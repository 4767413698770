<template>
  <div class="projects-drafts">
    <!-- HEADER / TITRE -->
    <div class='header grid-x'>
      <div class='cell auto'>
        <h1>Liste des avant-projets</h1>
      </div>
      <div class="cell shrink">
        <app-button
          theme="primary"
          size="large"
          icon="add"
          @click="goToCreateProjectDraft"
        >
          Ajouter un avant-projet
        </app-button>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="body">
      <!-- CONTENT -->
      <div class="grid-x">
        <!-- SEARCH BAR -->
        <div class="cell small-2 search" v-if="isBrandAdmin">
          <app-select :value="null" v-model="agencyId" :options="agencies" @input="agencyChange" />
        </div>

        <div class="cell shrink" v-if="isBrandAdmin">
          <app-button theme="secondary" size="small" @click="initSearch">Réinitialiser</app-button>
        </div>

        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterProjectDrafts"
            @reset="resetFilters"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-select
                  label="statut"
                  :value="$route.query.status"
                  v-model="filters.status"
                  :options="projectDraftStatuses"
                />
              </div>
            </div>
          </filter-modal>
        </div>
      </div>

      <!-- TABLE -->
      <div>
        <app-table
          :headers="tableHeader"
          :data="projectDrafts.data"
          :loading="isLoading"
          :isDisabledIf="isDisabledIf"
          @line-cliked="goToProjectDraft"
          clickable
        >
          <template slot="loading">
            <app-spinner />
          </template>
          <template slot="name" slot-scope="{ data }">
            <strong>
              <slot v-if="isDisabledIf(data)">
                <small class="error" v-if="!data.isInBuildingArea">❗️ COMMUNE HORS SECTEUR</small>
                <small class="error" v-if="data.land.deletedAt !== null">❗️ TERRAIN SUPPRIMÉ</small>
                <small class="error" v-if="data.version.deletedAt !== null">❗️VERSION SUPPRIMÉE</small>
                <small class="error" v-if="data.version.status === 'DRAFT'">❗️ VERSION BROUILLON</small>
              </slot>
              {{ data.name }}
            </strong>
          </template>
          <template slot="customer" slot-scope="{ data }">
            <p>{{ data.customer && data.customer.customerId ? getCustomersNames(data.customer) : '-' }}</p>
          </template>
          <template slot="city" slot-scope="{ data }">
            <p v-if="data.land && data.land.address && data.land.address.city">{{ data.land.address.city  }}</p>
            <p v-else-if="data.customerLand && data.customerLand.address && data.customerLand.address.city">{{ data.customerLand.address.city  }}</p>
          </template>
          <template slot="allotment" slot-scope="{ data }">
            <p v-if="data.land.allotment && data.land.allotment.allotmentId">
              <small>
                {{ data.land.allotment.name }}
                <slot v-if="data.land.lotNumber">- Lot {{ data.land.lotNumber }}</slot>
              </small>
            </p>
          </template>
          <template slot="version" slot-scope="{ data }">
            <p>{{ data.version.name }}</p>
          </template>
          <template slot="land" slot-scope="{ data }">
            <p v-if="data.land && data.land.landId">
              {{ `${data.land.surface} m²`}}
            </p>
            <p v-else-if="data.customerLand">
              {{ `${data.customerLand.surface} m²`}}
            </p>
            <p v-else>-</p>
          </template>

          <template slot="landPrice" slot-scope="{ data }">
            <p v-if="data.land && data.land.landId">
              {{ `${utils.formatCentToEuro(data.land.price)} €`}}
            </p>
            <p v-else-if="data.customerLand">
              {{ `${utils.formatCentToEuro(data.customerLand.price)} €`}}
            </p>
            <p v-else>-</p>
          </template>
          <template slot="status" slot-scope="{ data }">
            <p>{{ data.status ? projectDraftStatuses.find((status) => status.name === data.status).label : '' }}</p>
          </template>
          <template slot="totalPrice" slot-scope="{ data }">
            <p>
              {{ `${utils.formatCentToEuro(data.totalPrice)} €`}}
            </p>
          </template>
          <template slot="date" slot-scope="{ data }">
            <p>{{ $dayjs(data.updatedAt).format('DD/MM/YYYY') }}</p>
          </template>
          <template slot="empty-table">
            <p>Aucun avant-projet disponible</p>
          </template>
        </app-table>
      </div>
      <!-- PAGINATION -->
      <app-pagination
        v-if="projectDrafts.metadata"
        :limit="limit"
        :offset="projectDrafts.metadata.offset"
        :count="projectDrafts.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import customerApi from '@/services/api/customer';
import projectDraftApi from '@/services/api/projectDraft';
import utils from '@/services/utils/utils';
import projectDraftStatuses from '@/services/data/projectDraftStatuses.json';
import FilterModal from '@/components/general/FilterModal.vue';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';

export default {
  name: 'projects-drafts',
  components: {
    FilterModal,
  },
  metaInfo: {
    title: 'Liste des avant-projets',
  },
  data() {
    return {
      // Table
      tableHeader: [
        { label: 'Nom', key: 'name', size: 'auto' },
        { label: 'Prospect', key: 'customer', size: 'auto' },
        { label: 'Commune', key: 'city', size: 'auto' },
        { label: 'Version', key: 'version', size: 2 },
        { label: 'Lotissement', key: 'allotment', size: 'auto' },
        { label: 'Surface T.', key: 'land', size: 'auto' },
        { label: 'Prix T.', key: 'landPrice', size: 'auto' },
        { label: 'Status', key: 'status', size: 'auto' },
        { label: 'Prix Total', key: 'totalPrice', size: 'auto' },
        { label: 'Date', key: 'date', size: 'auto' },
      ],
      isLoading: false,
      projectDrafts: {
        data: null,
        metadata: null,
      },

      filters: {
        status: null,
      },
      limit: 10,
      projectDraftStatuses,
      isBrandAdmin: null,
      agencyId: null,
      agencies: [],
      utils,
    };
  },
  async mounted() {
    this.isLoading = true;

    this.filters.status = this.$route.query.status || null;

    await this.getMe();
    this.isBrandAdmin = this.me.isBrandAdmin;
    this.agencyId = this.$route.query.agencyId;

    if (this.isBrandAdmin) {
      await this.getAgencies();
    }

    await this.getProjectDrafts();

    this.isLoading = false;
  },
  watch: {
    // Déclenche la recherche de produits à chaque changement de query
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getProjectDrafts();
        this.isLoading = false;
      }
    },

    selectedView() {
      this.updateRoute();
    },
  },
  computed: {
    // Calcule de l'offset
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  methods: {
    initSearch() {
      this.agencyId = undefined;

      if (this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            agencyId: undefined,
            page: 1,
          },
        });
      }
    },

    // Récupération des avant-projets
    async getProjectDrafts() {
      try {
        this.projectDrafts = await projectDraftApi.getAll(
          this.limit,
          this.computeOffset,
          this.$route.query.agencyId,
          this.$route.query.status,
        );
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des avant-projets',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getMe() {
      try {
        this.me = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getAgencies() {
      try {
        const agencies = await agencyApi.getAll(this.search);
        const parsedAgencies = utils.formatOptions(
          agencies,
          (o) => o.agencyId,
          (o) => o.name,
          'choose',
        );
        parsedAgencies[0].label = 'Choisir une agence';
        this.agencies = parsedAgencies;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async filterProjectDrafts() {
      // On pousse les query dans l'URL une fois les nouveaux filtres valider
      this.$router.push({
        query: {
          ...this.$route.query,
          status: this.filters.status || undefined,
          page: 1,
        },
      });
    },

    resetFilters() {
      // On reset les valeurs de l'objet filters
      this.filters = {
        status: null,
      };

      // On supprime les query categoryId et lotId de l'URl
      this.$router.push({
        query: {
          ...this.$route.query,
          status: undefined,
        },
      });
    },

    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.status = this.$route.query.status || null;
    },

    agencyChange() {
      if (this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            agencyId: this.agencyId || undefined,
          },
        });
      }
    },

    // Redirection vers la page de création d'un avant-projet
    goToCreateProjectDraft() {
      this.$router.push({ name: 'customers-projects-drafts-new' });
    },

    // Redirection vers la page d'édition d'un terrain
    goToProjectDraft(projectDraft) {
      const route = this.$router.resolve({ name: 'customers-projects-drafts-projectDraftId', params: { projectDraftId: projectDraft.projectDraftId } });
      window.open(route.href, '_blank');
    },

    // Récupération du nom du client
    getCustomersNames(selectedCustomer) {
      return customerApi.getCustomersNames(selectedCustomer);
    },
    isDisabledIf(data) {
      return !data.isInBuildingArea || data.land.deletedAt !== null || data.version.deletedAt !== null || data.version.status === 'DRAFT';
    },
    isClickableIf(data) {
      return data.isInBuildingArea;
    },
  },
};
</script>

<style lang='sass' scoped>
.projects-drafts
  .body
    @include screen

  .header
    @include header
  .row
    @include row
  .filter-container
    margin-left: auto
  .search
    padding-bottom: 1rem
    margin-right: 1rem
    button
      margin-left: 1rem

  .filter-btn
    margin-left: 1rem

  .error
    display: block
    color: red
    white-space: nowrap

</style>
