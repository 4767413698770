<!--
*************
*** PROPS ***
*************

* headers : [Object] - liste d'objets décrivant les en-têtes des colonnes
Le champ "label" est celui qui sera utilisé pour l'affichage
Le champ "key" peut différer selon l'utilisation.
  - Si vous ne comptez pas personnaliser l'affichage des cases de la colonne, donc ne pas spécifier de slot.
    Alors le champ key doit correspondre à une propriété de l'objet passé en props "data".
    De cette façon le component affichera automatiquement la valeur de la propriété dans la case, dans une balise <p>
  - Maintenant, si vous comptez personnaliser l'affichage des cases de la colonne OU si votre propriété est nested (sous-objet).
    Alors le champ key servira seulement à retrouver la colonne lors de la spécification du template.
    Il doit donc être unique mais pas forcément correspondre à une propriété de l'objet en props "data"

Exemple:

[
  {
    label: 'Object ID',
    key: 'objectId', //  Ici, on affichera la propriété "objectId" de l'objet
  },
  {
    label: 'Color',
    key: 'color', //  Ici, on affichera la propriété "color" de l'objet
  },
  {
    label: 'Digit',
    key: 'metadata.pagination.page', // Ici, vu que les sous-objets ne sont pas pris en compte, il servira juste pour le retrouver dans les slots
  },
  {
    label: 'Bool',
    key: 'bool', //  Ici, on affichera la propriété "bool" de l'objet
  }
]

* data : [Object] - liste d'objets à afficher

Exemple:

[
  {
    objectId: '1',
    color: 'red',
    bool: true,
    metadata: {
      pagination: {
        page: 10,
      },
    },
  },
  {
    objectId: '2',
    color: 'blue',
    bool: true,
    metadata: {
      pagination: {
        page: 22,
      },
    },
  },
  {
    objectId: '3',
    color: 'yellow',
    bool: false,
    metadata: {
      pagination: {
        page: 57,
      },
    },
  }
]

* loading : Boolean - sert à changer l'affichage du tableau par le contenu passé dans le slot "loading"

*************
*** SLOTS ***
*************

Doc sur les scope-slots
- https://fr.vuejs.org/v2/guide/components-slots.html#Slots-avec-portee
- https://www.digitalocean.com/community/tutorials/vuejs-scoped-component-slots

Il est possible de personnaliser plusieurs éléments du tableau grâce aux "scope-slots".
On peut indiquer une balise "template" pour personnaliser l'affichage des cases d'une colonne,
sinon on garde l'affichage par défaut qui est un simple <p>

* header - Avec ce slot, on indique quel format auront les cases de header.
  L'objet "data" nous donne accès aux champs définis dans la liste "headers" en props.
  NOTE : si ce slot n'est pas spécifié, alors le champ "label" de l'objet passé en props "headers" sera affiché dans une balise <label>

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="header" slot-scope="{ data }">
      <p><strong>{{ data.label }}</strong></p>
    </template>
  </app-table>

* CLE_CHAMP - Alors pour ce slot, le nom dépend du champ "key" que vous avez défini dans la liste "headers" en props.
  L'objet "data" nous donne accès aux objets passés en props "data".
  NOTE : si ce slot n'est pas spécifié pour une colonne, alors la propriété définie par le champ "key" du header de l'objet sera affiché dans une balise <p>

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="objectId" slot-scope="{ data }">
      <p><strong>{{ data.objectId }}</strong></p>
    </template>

    <template slot="metadata.pagination.page" slot-scope="{ data }">
      <p><strong>{{ data.metadata.pagination.page }}</strong></p>
    </template>
  </app-table>

* empty-table - Avec ce slot on indique le contenu que l'on souhaite afficher lorsque la liste "data" en props est vide. On aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="empty-table">
      <p>Oups, c'est vide !</p>
    </template>
  </app-table>

* loading - Avec ce slot on indique le contenu que l'on souhaite afficher lorsque le props "loading" est à true. De la même manière que pour le slot "empty-table", on aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="loading">
      <p>LOADING...</p>
    </template>
  </app-table>
-->

<template>
  <section class="app-table grid-y">
    <div class="grid-x header" :class="{'table-min': small}">
      <div
        v-for="header in headers"
        :key="header.key"
        class="cell"
        :class="header.size === 'auto' || header.size === 'shrink' ? header.size : 'small-' + header.size">
        <slot v-if="$scopedSlots.header" name="header" :data="header"/>
        <h6 v-else>{{ header.label }}</h6>
      </div>
    </div>

    <div v-if="loading" class="pending cell auto" :class="{'table-min': small}">
      <slot name="loading"/>
    </div>

    <div v-else-if="data && data.length > 0" class="data cell" :class="{'table-min': small, 'auto': small, 'shrink': !small}">
      <div
        v-for="(line, index) in data"
        :key="index"
        class="line grid-x"
        :class="{
          'table-min': small,
          clickable: clickable && isClickableIf ? isClickableIf(line) : clickable,
          selected: isSelectedLine(line),
          'disabled': isDisabled(line),
        }"
        @click="emitClick(line)"
      >
        <div
          v-for="header in headers"
          :key="`${index}-${header.key}`"
          class="cell"
          :class="header.size === 'auto' || header.size === 'shrink' ? header.size : 'small-' + header.size">
          <!-- Affichage custom si le slot est spécifié -->
          <slot
            v-if="$scopedSlots[header.key]"
            :name="header.key"
            :data="line"
          />

          <!-- Affichage par défaut, juste du texte -->
          <p v-else>
            {{ line[header.key] }}
          </p>
        </div>
      </div>
    </div>

    <div v-else class="pending cell auto" :class="{ 'table-min': small }">
      <slot name="empty-table"/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'app-table',

  props: {
    headers: [Array],
    small: Boolean,
    clickable: Boolean,
    isClickableIf: Function,
    size: {
      type: String,
      validator: (val) => ['large', 'medium', 'small'].indexOf(val) !== -1,
      default: 'medium',
    },
    data: [Array],
    loading: [Boolean],
    // Définie une ligne sélectionnée selon la valeur d'une de ses clés
    selectedValue: [String],
    isDisabledIf: {
      type: Function,
      default: () => false,
    },
  },

  methods: {
    emitClick(line) {
      if (this.clickable && this.isClickableIf ? this.isClickableIf(line) : this.clickable) {
        this.$emit('line-cliked', line);
      }
    },
    isSelectedLine(value) {
      return !!Object.values(value).find((selected) => this.selectedValue === selected);
    },
    isDisabled(line) {
      return this.isDisabledIf(line);
    },
  },
};
</script>

<style lang="sass">
.app-table
  width: 100%
  height: 100%
  position: relative

  .header
    padding: .7rem 1.25rem .7rem 1.25rem
    .shrink
      padding-right: 20px
    div
      display: flex
      justify-content: flex-start
      align-items: center
      button
        padding: 0
    h6, label
      color: $info

  .header.table-min
    padding: 0 1.25rem
    background-color: $off-white
    box-shadow: none
    h6
      margin-bottom: 12px

  .data, .pending
    box-shadow: $drop-min

  .data
    overflow: auto
    background-color: $off-white
    .line
      display: flex
      flex-direction: row
      align-items: center
      margin: 0
      padding: 1rem 1.25rem
      background-color: $off-white
      text-align: left

      &:not(:last-child)
        border-bottom: 1px solid $line

      &.clickable
        cursor: pointer
        transition: background-color 0.3s
        &:hover
          background-color: rgba($background, 0.5)
        &.selected
          background-color: $background
      label
        width: 100%
      div
        box-sizing: border-box
        &:not(:last-child)
          padding-right: 20px
      .cell
        word-break: break-word
    .line.table-min
      width: auto
      padding: 1rem 1.25rem
      background-color: $background
      border-bottom: 1px solid $off-white
      p
        line-height: 1.3rem
    .line.disabled
      opacity: 0.5
      background-color: #d2d5e2
      &:hover
          background-color: #d2d5e2
  .pending
    min-height: 325px
    display: flex
    align-items: center
    justify-content: center
    background-color: $off-white
    p
      color: $info

  .data.table-min
    display: block
    width: 100%
    background-color: $background
    box-shadow: none

  .pending.table-min
    background-color: $background
    box-shadow: none
    color: $info
    min-height: auto

.tag
  @include tag

.tag-success
  @include tag-success

.tag-warning
  @include tag-warning

.tag-error
  @include tag-error

.tag-neutral
  @include tag-neutral
</style>
