<template>
  <div class="project-draft-configuration">
    <!-- BO ONLY -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="projectDraft && projectDraft.name"
          :items="[
            { route: { name: 'customers-projects-drafts'}, name: 'Avant-projets'},
            { route: { name: 'customers-projects-drafts-projectDraftId', params: { projectDraftId: $route.params.projectDraftId }}, name: projectDraft.name }
          ]"
        />
        <breadcrumb
          v-else
          :items="[
            { route: { name: 'customers-projects-drafts'}, name: 'Avant-projets'},
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell shrink">
        <h1>{{ projectDraft && projectDraft.name ? projectDraft.name : 'Nouvel avant-projet' }}</h1>

        <div class="project-infos" v-if="projectDraft && ((projectDraft.land && projectDraft.land.deletedAt !== null) || (projectDraft.version && (projectDraft.version.deletedAt !== null || projectDraft.version.status === 'DRAFT')))">
          ❗️ Attention cet avant-projet comporte un ou plusieurs éléments archivés : <br/>
          <slot v-if="projectDraft.version.status === 'DRAFT'">- la version associée est en brouillon<br/></slot>
          <slot v-if="projectDraft.version.deletedAt !== null">- la version associée est supprimée<br/></slot>
          <slot v-if="projectDraft.land.deletedAt !== null">- le terrain associé est supprimé<br/></slot>
        </div>
      </div>
    </div>
    <!-- COMMON PUBLIC -->
    <div class="configurator grid-x" v-if="configuration" :class="{ 'show-page': showPageContent }">
      <div class="sidebar cell small-12 medium-12 large-4">
        <!-- MENU DE LA CONFIGURATION -->
        <div class="head sidebar-cell">
          <router-link
            v-if="!$route.params.projectDraftId"
            :to="{
              name: 'customers-projects-drafts-new',
              query: {
                customerId: $route.query.customerId,
                postalCodeInseeNumber: $route.query.postalCodeInseeNumber,
                modelId: configuration.version.model.modelId,
                landChoice: configuration.land && configuration.land.landId ? 'land' : configuration.customerLand && configuration.customerLand.price && configuration.customerLand.width ? 'custom-land' : 'no-land',
                landWidth: $route.query.landWidth,
                landSurface: $route.query.landSurface,
                landPrice: $route.query.landPrice,
                isMainDrainagePlanned: $route.query.isMainDrainagePlanned,
                isServiced: $route.query.isServiced,
                isClayZone: $route.query.isClayZone,
                emptyLand: $route.query.emptyLand,
            }}">← Retour à la liste</router-link>
          <router-link
            v-else
            :to="{
              name: 'customers-projects-drafts-projectDraftId',
              query: {
                projectDraftId: $route.params.projectDraftId,
                modelId: configuration.version.model.modelId,
            }}">← Retour au résumé</router-link>
        </div>
        <section class="nav">
          <router-link :to="{ ...$route, params: { tab: 'version'} }" class="item grid-x sidebar-cell" @click.native="showPageContent = true">
            <div class="cell auto grid-y">
              <strong class="label">Maison {{ configuration.version.numberOfRooms }} chambre{{ configuration.version.numberOfRooms > 1 ? 's' : '' }}</strong>
              <small>{{ configuration.version.name }}</small>
            </div>
            <div class="cell shrink grid-y align-bottom">
              <small class="label">Prix initial</small>
              <strong class="highlight">{{ configuration.initialPrice ? utils.formatCentToEuro(
                configuration.initialPrice -
                (configuration.extensionsProperties && configuration.extensionsProperties.price ? configuration.extensionsProperties.price : 0)
              , true, true) : '0 €' }}</strong>
            </div>
          </router-link>

          <router-link  :to="{ ...$route, params: { ...$route.params, tab: 'surfaces'} }" class="item grid-x sidebar-cell" @click.native="showPageContent = true">
            <div class="cell auto grid-y">
              <strong class="label">Surfaces habitable : {{ Math.round(configuration.totalSurfaceHabitable) }} m²</strong>
              <small v-if="!configuration.extensionsProperties.selectedByCustomer && configuration.extensionsProperties.isActive">Agrandissement automatique</small>
              <small v-else>{{ configuration.extensionsProperties.maxSurface ? 'Modifier les surfaces' : 'Agrandissement impossible pour ce modèle' }}</small>
            </div>
            <div class="cell shrink grid-y align-bottom">
              <small class="label">Prix</small>
              <strong class="highlight">{{ configuration.extensionsProperties && configuration.extensionsProperties.price ? utils.formatCentToEuro(configuration.extensionsProperties.price, true, true) : 'BASE' }}</strong>
            </div>
          </router-link>

          <router-link :to="{ ...$route, params: { ...$route.params, tab: $route.query.packId ? 'categories': 'pack' } }"
            :class="{ 'router-link-active': $route.params.tab === 'options' || $route.params.tab === 'categories' || $route.params.tab === 'pack'}"
            class="item grid-x sidebar-cell"
            @click.native="showPageContent = true; themesTabs = themes.map((theme) => theme.themeId); isCollapseThemesInit = true;">
            <div class="cell auto grid-y">
              <strong class="label">Finitions &amp; Options</strong>
              <small>{{ configuration.packs && configuration.packs.length ? configuration.packs.find((pack) => pack.isActive).name : 'Pas de pack' }}</small>
            </div>
            <div class="cell shrink grid-y align-bottom">
              <small class="label">Prix</small>
              <strong class="highlight" v-if="configuration.packs.length === 3">
                {{
                  (configuration.packs.findIndex((pack) => pack.isActive) !== 1 || configuration.customerOptionsPrice) && ((configuration.customerOptionsPrice + (configuration.packs.find((pack) => pack.isActive).price - configuration.packs[1].price)) > 0) ? '+' : ''
                }}
                {{
                  configuration.packs.findIndex((pack) => pack.isActive) === 1 && !configuration.customerOptionsPrice ? 'BASE' :
                    configuration.customerOptionsPrice + (configuration.packs.find((pack) => pack.isActive).price - configuration.packs[1].price) ? utils.formatCentToEuro(configuration.customerOptionsPrice + (configuration.packs.find((pack) => pack.isActive).price) - configuration.packs[1].price, true, true) : '0 €'
                }}
              </strong>
              <strong class="highlight" v-else-if="configuration && configuration.version && configuration.version.range && configuration.version.range.name.includes('ACCESS')">
                {{
                  (configuration.packs.findIndex((pack) => pack.isActive) === 0 || configuration.customerOptionsPrice) && ((configuration.customerOptionsPrice + (configuration.packs.find((pack) => pack.isActive).price - configuration.packs[1].price)) > 0) ? '+' : ''
                }}
                {{
                  configuration.packs.findIndex((pack) => pack.isActive) !== 0 && !configuration.customerOptionsPrice ? 'BASE' :
                    configuration.customerOptionsPrice + (configuration.packs.find((pack) => pack.isActive).price - configuration.packs[1].price) ? utils.formatCentToEuro(configuration.customerOptionsPrice + (configuration.packs.find((pack) => pack.isActive).price - configuration.packs[1].price), true, true) : '0 €'
                }}
              </strong>
              <strong class="highlight" v-else-if="configuration.packs.length > 0">
                {{
                  (configuration.packs.findIndex((pack) => pack.isActive) !== 0 || configuration.customerOptionsPrice) && ((configuration.customerOptionsPrice + (configuration.packs.find((pack) => pack.isActive).price - configuration.packs[0].price)) > 0) ? '+' : ''
                }}
                {{
                  configuration.packs.findIndex((pack) => pack.isActive) === 0 && !configuration.customerOptionsPrice ? 'BASE' :
                    configuration.customerOptionsPrice + (configuration.packs.find((pack) => pack.isActive).price - configuration.packs[0].price) ? utils.formatCentToEuro(configuration.customerOptionsPrice + (configuration.packs.find((pack) => pack.isActive).price - configuration.packs[0].price), true, true) : '0 €'
                }}
              </strong>
            </div>
          </router-link>

          <router-link :to="{ ...$route, params: { ...$route.params, tab: 'land'} }" class="item grid-x sidebar-cell" @click.native="showPageContent = true">
            <div class="cell auto grid-y">
              <strong v-if="configuration.land && configuration.land.landId" class="label">{{ `Terrain à ${configuration.land.address.city}` }}</strong>
              <strong v-else-if="configuration.customerLand && configuration.customerLand.price && configuration.customerLand.width" class="label">Terrain Client</strong>
              <strong v-else class="label">{{ `Terrain à ${configuration.land.address.city}` }}</strong>

              <small class="highlight-missing" v-if="configuration.customerLand && !configuration.customerLand.price">A DÉFINIR</small>
              <small class="highlight-missing" v-else-if="configuration.customerLand && configuration.customerLand.width">{{ `Mon terrain Larg. ${configuration.customerLand.width} m` }}</small>
              <small v-else-if="configuration.land && configuration.land.allotment && configuration.land.allotment.allotmentId">{{ `Lotissement ${configuration.land.allotment.name}${configuration.land.lotNumber ? ` - Lot n°${configuration.land.lotNumber}` : ''} - ${configuration.land.surface} m²` }}</small>
              <small v-else-if="configuration.land && configuration.land.surface">{{ `Terrain ${configuration.land.surface} m²` }}</small>
            </div>
            <div class="cell shrink grid-y align-bottom">
              <small class="label">Prix</small>
              <strong class="highlight">{{ configuration.land && configuration.land.price ? utils.formatCentToEuro(configuration.land.price, true, true) : '-' }}</strong>
            </div>
          </router-link>

          <router-link  :to="{ ...$route, params: {...$route.params, tab: 'fees'} }" class="item grid-x sidebar-cell" @click.native="showPageContent = true; annexesTabs = annexLot ? [annexLot.lotId] : null;">
            <div class="cell auto grid-y">
              <strong class="label">Frais annexes</strong>
            </div>
            <div class="cell shrink grid-y align-bottom">
              <small class="label">Prix</small>
              <strong class="highlight">{{ configuration.annexOptionsPrice ? utils.formatCentToEuro(configuration.annexOptionsPrice, true, true) : '-' }}</strong>
            </div>
          </router-link>

          <div class="total sidebar-cell">
            <strong>Coût total du projet :</strong>
            <h3 class="price">{{ utils.formatCentToEuro(configuration.totalPrice, true, true) }}</h3>
          </div>

          <div class="actions">
            <router-link class="btn-action btn-strong" :to="{ ...$route, params: { ...$route.params, tab: 'save'} }" @click.native="showPageContent = true">
              Enregistrer le projet
            </router-link>
            <a id="pdfLink" :href="getPdfLink" target="_blank"></a>
          </div>
        </section>
      </div>
      <div ref="configuratorContent" class="content cell small-12 medium-12 large-auto"
        :style="{ backgroundImage: `url(${versionMedias && versionMedias.filter((media) => media.type === 'EXTERIOR_IMAGE') && versionMedias.filter((media) => media.type === 'EXTERIOR_IMAGE')[0] ? versionMedias.filter((media) => media.type === 'EXTERIOR_IMAGE')[0].url : ''})` }">
        <!-- DETAIL DU MENU OUVERT -->
        <div v-if="$route.params.tab === 'version'">
          <h4>{{ configuration.version.model.name }}</h4>
          <div class="tab-container">
            <div class="grid-x">
              <div class="cell large-auto medium-12 small-12">
                <h3>Maison {{ configuration.version.numberOfRooms }} chambre{{ configuration.version.numberOfRooms > 1 ? 's' : '' }}</h3>
              </div>
            </div>

            <p class="text-with-break">{{ configuration.version.description }}</p>

            <div v-if="versionMedias && versionMedias.length">
              <img class="version-media"
                v-for="(media, mediaIndex) in versionMedias"
                :key="media.versionMediaId"
                :src="media.url"
                @click="lightboxIndex = mediaIndex; isLightboxVisible = true;"
              />
              <small>Images non contractuelles</small>
            </div>
            <div v-else>
              <p>Pas d'images disponibles pour cette version</p>
            </div>
          </div>
        </div>

        <app-lightbox v-if="versionMedias && versionMedias.length"
          :show.sync="isLightboxVisible"
          :images="versionMedias.map((media) => media.url)"
          :index="lightboxIndex">
        </app-lightbox>

        <app-lightbox
          v-if="versionMedias && versionMedias.length"
          :show.sync="isLightboxBlueprintVisible"
          :images="versionMedias.filter((media) => media.type === 'BLUEPRINT').map((media) => media.url)"
          :index="0">
        </app-lightbox>

        <div v-if="$route.params.tab === 'surfaces'">
          <h4 class="desktop">Surfaces</h4>
          <div class="tab-container">
            <div class="grid-x">
              <div class="cell auto">
                <h3>Les surfaces de votre maison en détails</h3>
              </div>
            </div>
            <p>Vous pouvez modifier la surface de certaines pièces pré-définies pour réaliser un agrandissement de la surface totale de votre plan de maison (selon les modèles, à partir d'un ou deux axes coulissant(s) dans des proportions limitées par le configurateur). Dans certains cas, il est possible que cette fonctionnalité de soit pas accessible lorsque le configurateur procède à un agrandissement automatique pour adapter le plan de la maison aux dimensions du terrain.</p>
            <div>
              <div class="extension-link grid-x desktop" v-if="(configuration.version.isFirstExtensionActive || configuration.version.isSecondExtensionActive) && configuration.extensionsProperties.maxSurface">
                <div class="cell large-auto small-12 medium-12">
                  <h3>Gagnez jusqu'à {{ Math.round(configuration.extensionsProperties.maxSurface) }} m² <br>supplémentaires en surface totale</h3>
                </div>
                <div class="cell large-shrink small-12 medium-12">
                  <router-link :to="{ ...$route, params: { ...$route.params, tab: 'extensions'} }">
                    <app-button size="large" theme="primary">Modifier les surfaces</app-button>
                  </router-link>
                </div>
              </div>
              <div class="surfaces-card" v-if="configuration.version.surfaces && configuration.version.surfaces.length && configuration.version.surfaces.find((surface) => surface.room.type === 'HABITABLE' && surface.area > 0)">
                <div class="grid-x surfaces-header">
                  <div class="cell auto">
                    <h5 class="title">Surface totale habitable</h5>
                  </div>
                  <div class="cell shrink">
                    <p class="price">{{ configuration.totalSurfaceHabitable ? `${configuration.totalSurfaceHabitable.toFixed(2)} m²` : '' }}</p>
                  </div>
                </div>
                <div class="grid-x surface" v-for="surface in configuration.version.surfaces.filter((surface) => surface.room.type === 'HABITABLE' && surface.area > 0)" :key="surface.surfaceId">
                  <div class="cell auto">
                    <p>{{ surface.room.name }}</p>
                  </div>
                  <div class="cell shrink">
                    <p class="price extended" v-if="surface.extensionSurface">{{ (surface.extensionSurface).toFixed(2) }} m²</p>
                    <p class="price" v-else>{{ surface.area ? surface.area.toFixed(2) : 0 }} m²</p>
                  </div>
                </div>
              </div>
              <div class="surfaces-card" v-if="configuration.version.surfaces && configuration.version.surfaces.length && configuration.version.surfaces.find((surface) => surface.room.type !== 'HABITABLE')">
                <div class="grid-x surfaces-header">
                  <div class="cell auto">
                    <h5 class="title">Surface totale annexe</h5>
                  </div>
                  <div class="cell shrink">
                    <p class="price">{{ configuration.totalSurface  ? `${(configuration.totalSurface - (configuration.totalSurfaceHabitable ? configuration.totalSurfaceHabitable : 0)).toFixed(2)} m²` : '' }}</p>
                  </div>
                </div>
                <div class="grid-x surface" v-for="surface in configuration.version.surfaces.filter((surface) => surface.room.type !== 'HABITABLE')" :key="surface.surfaceId">
                  <div class="cell auto">
                    <p>{{ surface.room.name }}</p>
                  </div>
                  <div class="cell shrink">
                    <p class="price extended" v-if="surface.extensionSurface">{{ (surface.extensionSurface).toFixed(2) }} m²</p>
                    <p class="price" v-else>{{ surface.area ? (surface.area).toFixed(2) : 0 }} m²</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="cell shrink centered"
              v-if="versionMedias &&
              versionMedias.length &&
              versionMedias.filter((media) => media.type === 'BLUEPRINT') &&
              versionMedias.filter((media) => media.type === 'BLUEPRINT').length">
              <app-button theme="primary" @click="isLightboxBlueprintVisible = true">Voir les plans</app-button>
            </div>
          </div>
        </div>
        <div v-if="$route.params.tab === 'extensions'">
          <router-link :to="{ ...$route, params: { ...$route.params, tab: 'surfaces'} }"><h4 class="return-link">← Retour au tableau</h4></router-link>
          <div class="tab-container">
            <div class="grid-x row">
              <div class="cell large-auto medium-12 small-12">
                <h3>Personnalisez les surfaces de votre maison</h3>
              </div>
              <div class="cell large-shrink medium-12 small-12"
                v-if="versionMedias &&
                versionMedias.length &&
                versionMedias.filter((media) => media.type === 'BLUEPRINT') &&
                versionMedias.filter((media) => media.type === 'BLUEPRINT').length">
                <app-button theme="primary" @click="isLightboxBlueprintVisible = true">Voir les plans</app-button>
              </div>
            </div>
            <p>Tous nos plans de maisons permettent un agrandissement spécifique. Cet agrandissement peut s’effectuer soit à partir de la pièce de vie soit à partir du garage (ou encore des chambres), selon les modèles.Vous pouvez aussi répartir votre agrandissement entre ces 2 axes. Nous vous engageons également à étudier cette éventualité directement avec les conseils de votre constructeur</p>

            <div class="grid-x">
              <div class="cell auto extension-title grid-x surfaces-card margin-cards">
                <div class="cell auto">
                  <p class="desktop">Surface totale habitable</p>
                  <h1 class="extension-surface">
                    {{
                      configuration.version.surfaces.reduce(
                        (a, surface) => (
                          surface.room.type === 'HABITABLE' ? a + surface.area + (surface.firstExtensionDepth * firstExtensionCustomerWidth) + (surface.secondExtensionDepth * secondExtensionCustomerWidth) : a
                        ), 0
                      ).toFixed(2)
                    }} m²
                  </h1>
                  <div class="grid-x">
                    <div class="cell auto"></div>
                    <div class="cell shrink large-shrink medium-12 small-12">
                      <p class="extension-width">
                        Agrandissement:
                        <strong>{{
                          configuration.version.surfaces.reduce(
                            (a, surface) => (
                              surface.room.type === 'HABITABLE' ? a + (surface.firstExtensionDepth * firstExtensionCustomerWidth) + (surface.secondExtensionDepth * secondExtensionCustomerWidth) : a
                            ), 0
                          ).toFixed(2)
                        }} m²</strong>
                      </p>
                    </div>
                    <div class="cell large-shrink medium-12 small-12">
                      <p class="extension-width">Largeur de façade: <strong>{{ (configuration.version.width + firstExtensionCustomerWidth + secondExtensionCustomerWidth).toFixed(2) }} m</strong></p>
                    </div>
                    <div class="cell auto"></div>
                  </div>
                </div>
              </div>
              <div class="cell auto extension-title grid-x surfaces-card margin-cards">
                <div class="cell auto right">
                  <p class="desktop">Plus-value agrandissement</p>
                  <h1 class="extension-price">+ {{ utils.formatCentToEuro(getExtensionPrice(), true, true) }}</h1>
                </div>
              </div>
            </div>
            <div class="extension surfaces-card"
              v-if="
                configuration.version.isFirstExtensionActive &&
                (configuration.extensionsProperties.firstExtensionMaxWidth >= 0.1) &&
                configuration.version.surfaces.find((surface) => surface.firstExtensionDepth)
              " :class="{ 'disabled': isFirstExtensionValidated }" @click="editFirstExtension">
              <div class="grid-x extension-title">
                <div class="cell auto rooms">
                  <p>Effectuez un agrandissement à partir de l’axe {{ roomsFirstExtensions }}</p>
                </div>
              </div>
              <div class="slider-container">
                <app-slider
                  :disabled="isFirstExtensionValidated"
                  :class="{ 'disabled': isFirstExtensionValidated }"
                  :step="0.01"
                  :min="firstExtensionMinSlider()"
                  :max="firstExtensionMaxSlider()"
                  v-model="firstExtensionCustomerWidth"
                />
                <div class="grid-x">
                  <div class="cell shrink"><p>{{ firstExtensionMinSlider().toFixed(2) }} m</p><p>.min</p></div>
                  <div class="cell auto">
                    <span v-if="firstExtensionCustomerWidth" class="extension-value">{{ firstExtensionCustomerWidth ? firstExtensionCustomerWidth.toFixed(2) : 0 }} m</span>
                    <span v-else class="extension-value">0 m</span>
                  </div>
                  <div class="cell shrink"><p>{{ firstExtensionMaxSlider().toFixed(2) }} m</p><p>.max</p></div>
                </div>
              </div>
              <div v-if="configuration.version.surfaces && configuration.version.surfaces.length">
                <div class="grid-x room" v-for="surface in configuration.version.surfaces.filter((surface) => surface.firstExtensionDepth)" :key="surface.surfaceId">
                  <div class="cell auto area">
                    <p>{{ surface.area ? surface.area.toFixed(2) : 0 }} m²</p>
                  </div>
                  <div class="cell auto name">
                    <p>{{ surface.room.name }}<span> {{ (surface.area + (surface.firstExtensionDepth * firstExtensionCustomerWidth)).toFixed(2) }} m²</span></p>
                  </div>
                  <div class="cell auto max-area">
                    <p>{{ (surface.area + (surface.firstExtensionDepth * (configuration.extensionsProperties.firstExtensionMaxWidth ? configuration.extensionsProperties.firstExtensionMaxWidth : 0))).toFixed(2) }} m²</p>
                  </div>
                </div>
              </div>
              <div class="grid-x footer" v-if="configuration.version.isSecondExtensionActive && !isExtensionAuto">
                <div class="cell auto">
                  <p>* Vous devez d’abord valider votre premier agrandissement pour passer au suivant.</p>
                </div>
                <div class="cell shrink">
                  <app-button size="large" theme="error" @click.stop="validateFirstExtension" :disabled="isFirstExtensionValidated">Valider</app-button>
                </div>
              </div>
            </div>
            <div
              v-if="
                configuration.version.surfaces &&
                configuration.version.surfaces.length &&
                configuration.version.isSecondExtensionActive &&
                (
                  (configuration.extensionsProperties.secondExtensionMaxWidth > configuration.extensionsProperties.maxWidth - firstExtensionCustomerWidth && configuration.extensionsProperties.maxWidth - firstExtensionCustomerWidth >= 0.1) ||
                  (configuration.extensionsProperties.secondExtensionMaxWidth <= configuration.extensionsProperties.maxWidth - firstExtensionCustomerWidth && configuration.extensionsProperties.secondExtensionMaxWidth >= 0.1)
                ) &&
                configuration.version.surfaces.find((surface) => surface.secondExtensionDepth) &&
                (secondExtensionMaxSlider() > 0)"
              class="extension surfaces-card" :class="{ 'disabled': !isFirstExtensionValidated }">
              <div class="grid-x extension-title">
                <div class="cell auto rooms">
                  <p>Effectuez un agrandissement à partir de l’axe {{ roomsSecondExtensions }}</p>
                </div>
              </div>
              <div class="slider-container">
                <app-slider
                  :disabled="!isFirstExtensionValidated"
                  :class="{ 'disabled': !isFirstExtensionValidated }"
                  :step="0.01"
                  :min="0"
                  :max="secondExtensionMaxSlider()"
                  v-model="secondExtensionCustomerWidth"
                />
                <div class="grid-x">
                  <div class="cell shrink"><p>0 m</p><p>.min</p></div>
                  <div class="cell auto">
                    <span v-if="secondExtensionCustomerWidth" class="extension-value">{{ secondExtensionCustomerWidth ? secondExtensionCustomerWidth.toFixed(2) : 0 }} m</span>
                    <span v-else class="extension-value">0 m</span>
                  </div>
                  <div class="cell shrink"><p>{{ secondExtensionMaxSlider().toFixed(2) }} m</p><p>.max</p></div>
                </div>
              </div>
              <div class="grid-x room" v-for="surface in configuration.version.surfaces.filter((surface) => surface.secondExtensionDepth)" :key="surface.surfaceId">
                <div class="cell auto area">
                  <p>{{ surface.area ? surface.area.toFixed(2) : 0 }} m²</p>
                </div>
                <div class="cell auto name">
                  <p>{{ surface.room.name }} <span>{{ (surface.area + (surface.secondExtensionDepth * secondExtensionCustomerWidth)).toFixed(2) }} m²</span></p>
                </div>
                <div class="cell auto max-area">
                  <p>{{ (surface.area + (surface.secondExtensionDepth * (configuration.extensionsProperties.maxWidth - firstExtensionCustomerWidth))).toFixed(2) }} m²</p>
                </div>
              </div>
            </div>
            <div class="submit">
              <app-button v-if="isFirstExtensionValidated || !configuration.version.isSecondExtensionActive || isExtensionAuto" @click="applyExtensions">Modifier les surfaces</app-button>
            </div>
          </div>
        </div>

        <div v-if="$route.params.tab === 'pack'">
          <h4 class="desktop">Finitions &amp; Options</h4>
          <div class="tab-container">
            <h3>Choisissez le niveau de finition de votre maison</h3>
            <p v-if="configuration && configuration.version && configuration.version.range && configuration.version.range.name.includes('ACCESS')">
              Vous avez le choix entre 2 niveaux de finition : EKO et ACCESS'. Chacun de ces packs vous offre une pré-sélection d'options que vous pouvez également personnaliser en cliquant sur « Découvrir ».
            </p>
            <p v-else>
              Vous avez le choix entre 3 niveaux de finition : EKO, ESSENTIEL et PREMIUM. Chacun de ces packs vous offre une pré-sélection d’options que vous pouvez également personnaliser en cliquant sur « Découvrir ».
            </p>
            <div class="packs grid-x">
              <div class="pack cell large-shrink medium-12 small-12"
                v-for="(pack, index) in configuration.packs" :key="index"
                @click="packId = pack.packId, packChange()"
                :id="pack.name"
                :class="{ active: packId === pack.packId}">
                <div class="title">
                  <small>PACK</small>{{ pack.name }}
                </div>
                <div class="choice" v-if="configuration.packs && configuration.packs.length === 3">
                  <app-radio :value="pack.packId" v-model="packId" @input="packChange"></app-radio>
                  <span v-if="index === 1">BASE</span>
                  <span v-else-if="pack.price - configuration.packs[1].price > 1">+ {{ utils.formatCentToEuro(pack.price - configuration.packs[1].price, true, true) }}</span>
                  <span v-else>{{ utils.formatCentToEuro(pack.price - configuration.packs[1].price, true, true) }}</span>
                </div>
                <div class="choice" v-else-if="configuration && configuration.version && configuration.version.range && configuration.version.range.name.includes('ACCESS')">
                  <app-radio :value="pack.packId" v-model="packId" @input="packChange"></app-radio>
                  <span v-if="pack.packId === 'c198d8f7-cd96-4b9b-a0d9-d927429356e3'">BASE</span>
                  <span v-else-if="pack.price - configuration.packs.find((pack) => pack.packId === 'c198d8f7-cd96-4b9b-a0d9-d927429356e3').price > 1">+ {{ utils.formatCentToEuro(pack.price - configuration.packs.find((pack) => pack.packId === 'c198d8f7-cd96-4b9b-a0d9-d927429356e3').price, true, true) }}</span>
                  <span v-else>{{ utils.formatCentToEuro(pack.price - configuration.packs.find((pack) => pack.packId === 'c198d8f7-cd96-4b9b-a0d9-d927429356e3').price, true, true) }}</span>
                </div>
                <div class="choice" v-else>
                  <app-radio :value="pack.packId" v-model="packId" @input="packChange"></app-radio>
                  <span v-if="index === 0">BASE</span>
                  <span v-else-if="pack.price - configuration.packs[0].price > 1">+ {{ utils.formatCentToEuro(pack.price - configuration.packs[0].price, true, true) }}</span>
                  <span v-else>{{ utils.formatCentToEuro(pack.price - configuration.packs[0].price, true, true) }}</span>
                </div>
                <router-link v-if="packId === pack.packId" class="config-btn" :to="{ ...$route, params: { ...$route.params, tab: 'categories'} }">Découvrir</router-link>
                <button v-else class="config-btn" @click="packId = pack.packId, packChange()">Choisir ce pack</button>
              </div>
            </div>

            <button v-if="configuration && configuration.version && configuration.version.range && !configuration.version.range.name.includes('ACCESS')" class="more" @click="openMoreTab = !openMoreTab ? 'more' : null">En savoir +</button>

            <app-collapse v-model="openMoreTab" name="more">
              <div class="more-informations">
                <h5>Comparez les caractéristiques :</h5>
                <p>Chaque pack est pré-optionné pour vous offrir une sélection d’options idéale pour un rapport équipements/prix équilibré.</p>
                <img src="/img/configuration/packs.jpg" alt="Comparatif packs">
                <h5>Questions fréquentes</h5>

                <div class="faq">
                  <div class="item">
                    <div class="question"
                      :class="{ active: faqTab === 'question1'}"
                      @click="faqTab = faqTab === 'question1' ? null : 'question1'">
                        Qu’est-ce qu’un pack Maisons Alysia ?
                    </div>
                    <app-collapse class="answer" v-model="faqTab" name="question1">
                      Un Pack est un groupe d’options (équipements, fournitures… ) attribué à un niveau de finition (Eko, Essentiel ou Premium). Chaque pack est pré-optionné pour vous offrir une sélection d’options idéale lui conférant un rapport équipements/prix équilibré.
                    </app-collapse>
                  </div>
                  <div class="item">
                    <div class="question"
                      :class="{ active: faqTab === 'question2'}"
                      @click="faqTab = faqTab === 'question2' ? null : 'question2'">
                        Est-ce que je peux modifier un Pack ?
                    </div>
                    <app-collapse class="answer" v-model="faqTab" name="question2">
                      Vous pouvez personnaliser un pack en modifiant la sélection des options dans la section « Configurez ». Activez ou désactivez les options proposées au gré de vos choix. Il existe quelques options non modifiables (bouton grisé).
                    </app-collapse>
                  </div>
                  <div class="item">
                    <div class="question"
                      :class="{ active: faqTab === 'question3'}"
                      @click="faqTab = faqTab === 'question3' ? null : 'question3'">
                        Que se passe-t-il lorsque je change de modèle de maison ?
                    </div>
                    <app-collapse class="answer" v-model="faqTab" name="question3">
                      Que vous ayez ou non personnalisé une maison, lorsque vous changez de modèle vous conservez le Pack que vous avez sélectionné. Si vous avez personnalisé votre Pack, le changement de modèle réinitialise la configuration de base du pack. Si vous souhaitez conserver une trace de votre configuration, pensez à enregistrer votre projet sans votre espace personnalisé.
                    </app-collapse>
                  </div>
                  <div class="item">
                    <div class="question"
                      :class="{ active: faqTab === 'question4'}"
                      @click="faqTab = faqTab === 'question4' ? null : 'question4'">
                        Que se passe-t-il lorsque je change de Pack ?
                    </div>
                    <app-collapse class="answer" v-model="faqTab" name="question4">
                      Lorsque vous changez de Pack, votre précédente configuration sera perdue car vous changez de référentiel d’options et les précédentes données ne sont pas mémorisées.
                    </app-collapse>
                  </div>
                  <div class="item">
                    <div class="question"
                      :class="{ active: faqTab === 'question5'}"
                      @click="faqTab = faqTab === 'question5' ? null : 'question5'">
                        Comment faire pour ne pas perdre ma configuration ?
                    </div>
                    <app-collapse class="answer" v-model="faqTab" name="question5">
                      Vous pouvez personnaliser un pack en modifiant la sélection des options dans la section « Configurez ». Activez ou désactivez les options proposées au gré de vos choix. Il existe quelques options non modifiables (bouton grisé).
                    </app-collapse>
                  </div>
                  <div class="item">
                    <div class="question"
                      :class="{ active: faqTab === 'question6'}"
                      @click="faqTab = faqTab === 'question6' ? null : 'question6'">
                        Est-ce que je peux modifier un Pack ?
                    </div>
                    <app-collapse class="answer" v-model="faqTab" name="question6">
                      Que vous souhaitiez changer de pack pour un même modèle de maison ou simplement conserver votre configuration, vous avez la possibilité de sauvegarder votre projet dans espace personnalisé.
                    </app-collapse>
                  </div>
                  <div class="item">
                    <div class="question"
                      :class="{ active: faqTab === 'question7'}"
                      @click="faqTab = faqTab === 'question7' ? null : 'question7'">
                        Comment aller plus loin dans ma personnalisation d’options ?
                    </div>
                    <app-collapse class="answer" v-model="faqTab" name="question7">
                      Il existe un mode avancé de configuration uniquement disponible en agence. N’hésitez pas à contacter votre agence et prendre rendez-vous avec votre constructeur pour déterminer encore plus précisémet les caractéristiques de votre future maison et continuer l’aventure !
                    </app-collapse>
                  </div>
                </div>
              </div>
            </app-collapse>
          </div>
        </div>

        <div v-if="$route.params.tab === 'categories'">
          <h4 v-if="configuration.packs.length > 1">
            <router-link class="change-pack" :to="{ ...$route, params: { ...$route.params, tab: 'pack'} }">← Changer le pack</router-link>
          </h4>
          <div class="tab-container" v-if="lots && categories && !isLoading && pack">
            <div class="card-container">
              <div class="pack-description">
                <h3>{{ pack.title }}</h3>
                <p v-html="pack.description"></p>
              </div>
              <div class="pack-content">
                <div class="pack-title-container">
                  <p class="pack-pack">PACK</p>
                  <p class="pack-title" :class="{ 'pack-underline-premium': pack.name === 'PREMIUM', 'pack-underline-eko': pack.name === 'EKO', 'pack-underline-essentiel': pack.name === 'ESSENTIEL', 'pack-underline-prime': pack.name === 'PRIME', 'pack-underline-access': pack.name.includes('ACCESS') }">{{ pack.name }}</p>
                  <p :class="{ 'pack-options-premium': pack.name === 'PREMIUM', 'pack-options-eko': pack.name === 'EKO', 'pack-options-essentiel': pack.name === 'ESSENTIEL', 'pack-options-prime': pack.name === 'PRIME', 'pack-options-access': pack.name.includes('ACCESS') }">{{ pack.numberOfOptions }} options personnalisables</p>
                </div>
                <div class="pack-items">
                  <ul>
                    <li class="options-included" v-for="option in pack.optionsIncluded" :key="option">
                      <checked :class="{ 'checked-premium': pack.name === 'PREMIUM', 'checked-eko': pack.name === 'EKO', 'checked-essentiel': pack.name === 'ESSENTIEL', 'checked-prime': pack.name === 'PRIME', 'checked-access': pack.name.includes('ACCESS') }" />  {{ option }}
                    </li>
                  </ul>
                  <ul>
                    <li class="options-not-included" v-for="option in pack.optionsNotIncluded" :key="option">
                      <cancel class="cancel" /> {{ option }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="collapse-items lots">
              <div class="items-header">
                <h3>Ma configuration</h3>
                <div class="pack-total">
                  <div>
                    <p class="pack-total-options">
                      <strong>Montant total de vos options :</strong>
                    </p>
                    <p class="pack-total-options">
                      <strong>dont options hors-contrat<sup>*</sup>:
                        {{
                          utils.formatCentToEuro(
                            configuration.options
                              .filter((option) => {
                                return option.isActive && option.type !== 'ANNEX' && !option.theme.isContractIncluded && option.category.categoryId !== 'c68c8545-09fd-4d57-a013-d4bfcfb673c2';
                              })
                              .reduce((a, option) => (option.price ? option.price : 0) + a, 0), true, true)
                        }}
                      </strong>
                    </p>
                  </div>
                  <div>
                    <p class="pack-price" :class="{ 'option-premium': pack.name === 'PREMIUM', 'option-eko': pack.name === 'EKO', 'option-essentiel': pack.name === 'ESSENTIEL', 'option-prime': pack.name === 'PRIME', 'option-access': pack.name.includes('ACCESS') }">
                      {{
                        utils.formatCentToEuro(
                          configuration.options
                            .filter((option) => {
                              return option.isActive && option.type !== 'ANNEX' && option.category.categoryId !== 'c68c8545-09fd-4d57-a013-d4bfcfb673c2';
                            })
                            .reduce((a, option) => (option.price ? option.price : 0) + a, 0),
                          true,
                          true,
                        )
                      }}</p>
                  </div>
                </div>
                <p class="option-customising" :class="{ 'option-premium': pack.name === 'PREMIUM', 'option-eko': pack.name === 'EKO', 'option-essentiel': pack.name === 'ESSENTIEL', 'option-prime': pack.name === 'PRIME', 'option-access': pack.name.includes('ACCESS') }">Découvrez et personnalisez vos options</p>
              </div>
              <div class="item" v-for="category in categories" :key="category.categoryId" :class="{ 'item-premium': pack.name === 'PREMIUM', 'item-eko': pack.name === 'EKO', 'item-essentiel': pack.name === 'ESSENTIEL', 'item-prime': pack.name === 'PRIME', 'item-access': pack.name.includes('ACCESS') }">
                <div class="head"
                  :class="{ active: categoriesTabs.includes(category.categoryId) }"
                  @click="toggleArrayValue(categoriesTabs, category.categoryId)"
                >
                  <div>
                    <arrow />
                    <strong>{{ category.name }}</strong>
                  </div>
                  <div>
                    <img v-if="category.icon" :src="category.icon" />
                  </div>
                </div>
                <app-collapse class="ctn" v-model="categoriesTabs" :name="category.categoryId" v-if="lots && lots.length">
                  <div
                    class="option with-arrow grid-x"
                    v-for="lot in lots.filter((l) => $route.query.landId ? l.categoryId === category.categoryId : l.categoryId === category.categoryId && l.lotId !== 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35' && l.lotId !== 'a6fef712-5d4a-46f4-8866-c92e16baf391')"
                    :key="lot.lotId"
                  >
                    <router-link :to="{ ...$route, params: { ...$route.params, tab: 'options'} }">
                      <div class="option-card" @click="selectedLot = lot.lotId">
                        <div class="option-card-img">
                          <img :src="lot.image" alt="">
                        </div>
                        <div class="option-card-container">
                          <div class="option-card-content">
                            <div>
                              <strong>{{ lot.name }}</strong>
                              <small>Descriptif {{ configuration.options.find((option) => option.lot.lotId === lot.lotId) ? `+ ${configuration.options.filter((option) => option.lot.lotId === lot.lotId).length} option${ configuration.options.filter((option) => option.lot.lotId === lot.lotId).length > 1 ? 's' : '' }` : '' }}</small>
                            </div>
                            <div>
                              <strong v-if="configuration.options && configuration.options.length && category.categoryId !== 'c68c8545-09fd-4d57-a013-d4bfcfb673c2'">
                                {{
                                  configuration.options.filter((option) => option.lot.lotId === lot.lotId && option.isActive && option.type !== 'ANNEX').reduce((a, option) => option.price + a, 0) > 0 ?
                                    `+ ${utils.formatCentToEuro(
                                      configuration.options.filter((option) => option.lot.lotId === lot.lotId && option.isActive && option.type !== 'ANNEX').reduce((a, option) => option.price + a, 0), true, true)}`
                                      :
                                      configuration.options.filter((option) => option.lot.lotId === lot.lotId && option.isActive && option.type !== 'ANNEX').reduce((a, option) => option.price + a, 0) === 0 ? '' :
                                        utils.formatCentToEuro(
                                          configuration.options.filter((option) => option.lot.lotId === lot.lotId && option.isActive && option.type !== 'ANNEX').reduce((a, option) => (option.price ? option.price : 0) + a, 0), true, true)
                                }}
                              </strong>
                            </div>
                          </div>
                          <div class="option-card-btn">
                            <span>
                              <arrow-left />
                            </span>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </app-collapse>
              </div>
            </div>
            <p class="footnote">* Options Hors contrat : Proposés dans le configurateur, ces options et équipements sont intégrés au prix total du projet, donc à votre financement, au calcul de votre mensualité ainsi qu’au descriptif complet de votre projet. En revanche, ils ne figurent pas au contrat de construction. Ces options et équipements concernent : la cuisine aménagée, les meubles de salle de bains, le revêtement du sol des chambres, les portes de placards et la peinture intérieure (fourniture et pose).</p>
          </div>
          <div v-else class="label">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div v-if="$route.params.tab === 'options' && lots && categories && selectedLot">
          <h4>
            <router-link class="change-pack" :to="{ ...$route, params: { ...$route.params, tab: 'categories'} }">← Descriptifs &amp; Options</router-link>
          </h4>
          <div class="tab-container">
            <div class="grid-x lot">
              <div class="cell shrink desktop">
                <img v-if="lots.find((lot) => lot.lotId === selectedLot).image" :src="lots.find((lot) => lot.lotId === selectedLot).image" />
              </div>
              <div class="cell small-12 medium-12 large-auto">
                <h3>{{ lots.find((lot) => lot.lotId === selectedLot).name }}</h3>
                <p class="text-with-break">{{ lots.find((lot) => lot.lotId === selectedLot).description }}</p>
              </div>
            </div>
            <div class="collapse-items lot-options row" v-if="themes && themes.length">
              <div class="item descriptif" v-for="theme in themes.filter((theme) => theme.lot.lotId === selectedLot)" :key="theme.themeId">
                <div class="head"
                  v-if="configuration.options.find((o) => o.theme.themeId === theme.themeId)"
                  :class="{ active: themesTabs.includes(theme.themeId), 'item-premium': pack.name === 'PREMIUM', 'item-eko': pack.name === 'EKO', 'item-essentiel': pack.name === 'ESSENTIEL', 'item-prime': pack.name === 'PRIME', 'item-access': pack.name.includes('ACCESS') }"
                  @click="toggleArrayValue(themesTabs, theme.themeId)"
                >
                  <div class="option-item">
                    <arrow />
                    <strong>{{ theme.name }}</strong>
                  </div>
                </div>
                <app-collapse
                  v-if="configuration.options.find((o) => o.theme.themeId === theme.themeId)"
                  class="ctn"
                  v-model="themesTabs"
                  :name="theme.themeId">
                  <div v-if="theme.behavior === 'ONE_REQUIRED' && configuration.options && configuration.options.length">
                    <div
                      class="option grid-x option-descriptif"
                      v-for="option in configuration.options.filter((o) => o.theme.themeId === theme.themeId)"
                      :key="option.optionId"
                    >
                      <div class="cell auto grid-x">
                        <div class="cell large-shrink medium-12 small-12">
                          <img v-if="option.image" :src="option.image" alt="">
                        </div>
                        <div class="cell large-auto medium-12 small-10 label">
                          <strong>{{ option.name }}</strong>
                          <small class="text-with-break">{{ option.description }}</small>
                        </div>
                        <div class="cell large-shrink medium-12 small-12 price desktop" v-if="option.category.categoryId !== 'c68c8545-09fd-4d57-a013-d4bfcfb673c2' || (option.category.categoryId === 'c68c8545-09fd-4d57-a013-d4bfcfb673c2' && (option.lot.lotId === 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35' || option.lot.lotId === 'a6fef712-5d4a-46f4-8866-c92e16baf391'))">
                          <strong v-if="option.type === 'BASE'">BASE</strong>
                          <strong v-else-if="option.type === 'ANNEX'">ANNEXE</strong>
                          <strong v-else>{{ option.price > 0 && option.lot.lotId !== 'b7f6d4be-5cc7-472b-9bea-44d99d35dc35' && option.lot.lotId !== 'a6fef712-5d4a-46f4-8866-c92e16baf391' ? '+ ' : '' }}{{ utils.formatCentToEuro(option.price, true, true) }}</strong>
                        </div>
                      </div>
                      <div class="cell shrink option-input">
                        <app-radio
                          v-model="optionsByThemes[theme.themeId]"
                          :value="option.optionId"
                          @input="() => updateConfig()"
                          :disabled="option.isDisabled"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else-if="configuration.options && configuration.options.length">
                    <div
                      class="option option-descriptif grid-x"
                      v-for="option in configuration.options.filter((o) => o.theme.themeId === theme.themeId)"
                      :key="option.optionId"
                    >
                      <div class="cell auto grid-x">
                        <div class="cell large-shrink medium-12 small-12">
                          <img v-if="option.image" :src="option.image" alt="">
                        </div>
                        <div class="cell large-auto medium-12 small-10 label">
                          <strong>{{ option.name }}</strong>
                          <small class="text-with-break">{{ option.description }}</small>
                        </div>
                        <div class="cell large-shrink medium-12 small-12 price desktop" v-if="option.category.categoryId !== 'c68c8545-09fd-4d57-a013-d4bfcfb673c2'">
                          <strong v-if="option.type === 'BASE'">BASE</strong>
                          <strong v-else-if="option.type === 'ANNEX'">ANNEXE</strong>
                          <strong v-else>{{ option.price > 0 ? '+ ' : '' }}{{ utils.formatCentToEuro(option.price, true, true) }}</strong>
                        </div>
                      </div>
                      <div class="cell shrink option-input">
                        <option-checkbox
                          v-model="optionsByThemes[theme.themeId]"
                          :value="option.optionId"
                          @input="() => optionChange(theme.themeId, option.optionId)"
                          :required="true"
                          :disabled="
                            (optionsByThemes[theme.themeId] &&
                            optionsByThemes[theme.themeId].length === 1 &&
                            optionsByThemes[theme.themeId][0] === option.optionId &&
                            theme.behavior === 'MANY_REQUIRED') || option.isDisabled
                          "
                        />
                      </div>
                    </div>
                  </div>
                </app-collapse>
              </div>
            </div>
            <div class="grid-x row">
              <div class="cell shrink lot-nav" v-if="lots && lots.length && (lots.findIndex((lot) => lot.lotId === selectedLot) > 0)">
                <app-button @click="previousLot"><arrow-left />Lot précédent</app-button>
              </div>
              <div class="cell auto"></div>
              <div class="cell shrink lot-nav" v-if="lots && lots.length && (lots.findIndex((lot) => lot.lotId === selectedLot) < lots.length-1)">
                <app-button @click="nextLot">Lot suivant<arrow-left /></app-button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$route.params.tab === 'land' && configuration && configuration.land && configuration.land.landId">
          <h4 class="desktop" v-if="configuration.land && configuration.land.allotment && configuration.land.allotment.allotmentId">Terrain</h4>
          <h4 class="desktop" v-else-if="configuration.land && configuration.land.surface">Terrain</h4>
          <div class="tab-container" v-if="lots && categories">
            <h3>Votre terrain à {{ configuration.land.address.city }}</h3>
            <p>La sélection de votre recherche terrain.</p>
          </div>
          <div class="allotment-container">
            <div class="allotment-header grid-x">
              <div class="cell auto">
                <h5>{{ configuration.land.allotment && configuration.land.allotment.name ? configuration.land.allotment.name : 'Terrains sans lotissements' }}</h5>
                <div class="company" v-if="configuration.land.company && configuration.land.company.companyId">
                  <p>Lotissement proposé par notre partenaire</p>
                  <p class="company-name">{{ configuration.land.company.name }}</p>
                </div>
              </div>
              <div
                v-if="configuration.land.allotment && configuration.land.allotment.presentationPdf && configuration.land.allotment.presentationPdf.includes('https://')"
                class="cell shrink download"
                @click="downloadPdf(configuration.land.allotment)">
                <document />
                <p>Voir la fiche<br>lotissement</p>
              </div>
            </div>
            <div class="allotment-lands">
              <div class="grid-x">
                <div class="cell auto land-name">
                  <p>{{ configuration.land.lotNumber ? `Lot n°${configuration.land.lotNumber} - ` : '' }}{{ configuration.land.surface ? `${configuration.land.surface} m²` : '' }}</p>
                </div>
                <div class="cell shrink">
                  <h3>{{ configuration.land.price || configuration.land.price === 0 ? utils.formatCentToEuro(configuration.land.price, true, true)  : '' }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-x">
            <div class="cell auto"></div>
            <div class="cell shrink">
              <app-button @click="() => changeLand('land')">Changer de terrain</app-button>
            </div>
          </div>
        </div>

        <div v-if="$route.params.tab === 'land' && configuration && configuration.customerLand && configuration.customerLand.width && configuration.customerLand.price !== 0">
          <h4 class="desktop">Terrain</h4>
          <div class="tab-container" v-if="lots && categories">
            <h3>Je possède un terrain à {{ configuration.land.address.city }}</h3>
            <p>Vous avez renseigné les caractéristiques suivantes :</p>
          </div>
          <div class="custom-land-container">
            <div class="land-name">
              <div class="grid-x">
                <div class="cell auto land-name">
                  <div>
                    <p>Mon terrain {{ configuration.customerLand.width ? `Larg. ${configuration.customerLand.width} m` : '' }}</p>
                    <p>
                      <small v-if="typeof configuration.customerLand.isMainDrainagePlanned === 'boolean'">{{ configuration.customerLand.isMainDrainagePlanned ? 'Tout à l\'égout, ': 'Assainissement autonome, ' }}</small>
                      <small v-if="typeof configuration.customerLand.isServiced === 'boolean'">{{ configuration.customerLand.isServiced ? 'est viabilisé': 'n\'est pas viabilisé' }}</small>
                      <small v-if="typeof configuration.customerLand.isClayZone === 'boolean'">{{ configuration.customerLand.isClayZone ? ', zone argileuse ': '' }}</small>
                    </p>
                  </div>
                </div>
                <div class="cell shrink">
                  <h3>{{ configuration.customerLand.price || configuration.customerLand.price === 0 ? utils.formatCentToEuro(configuration.customerLand.price, true, true)  : '' }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-x">
            <div class="cell auto"></div>
            <div class="cell shrink">
              <app-button @click="() => changeLand('custom-land')">Modifier le terrain</app-button>
            </div>
          </div>
        </div>

        <div v-if="$route.params.tab === 'land' && configuration && configuration.customerLand && !configuration.customerLand.price">
          <h4 class="desktop">Terrain</h4>
          <div class="tab-container" v-if="lots && categories">
            <h3>Définir mon terrain à {{ configuration.land.address.city }}</h3>
            <p>Pour finaliser votre projet, un terrain est nécessaire. Vous pouvez en chercher sur la commune sélectionnée ou ajouter le vôtre.</p>
            <div class="extension-link light grid-x">
              <div class="cell large-auto medium-12 small-12">
                <h3>Trouvez un terrain à {{ configuration.land.address.city }}</h3>
              </div>
              <div class="cell large-shrink medium-12 small-12">
                <app-button @click="() => changeLand('land')">Chercher un terrain</app-button>
              </div>
            </div>
            <div class="extension-link grid-x">
              <div class="cell large-auto medium-12 small-12">
                <h3>Vous avez un terrain ?</h3>
              </div>
              <div class="cell large-shrink medium-12 small-12">
                <app-button size="large" theme="primary" @click="() => changeLand('custom-land')">Configurer mon terrain</app-button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$route.params.tab === 'fees'">
          <h4 class="desktop">Frais annexes</h4>
          <div class="tab-container" v-if="lots && annexLot">
            <div class="grid-x">
              <div class="cell auto">
                <h3>Les frais annexes de votre projet</h3>
                  <p class="text-with-break">
                    Le prix total estimé est principalement constitué de ceux du terrain et de la maison mais pas seulement. <br>
                    Pour éviter les mauvaises surprises, il faut rajouter les frais annexes techniques, administratifs et fiscaux indispensables à l’élaboration de votre plan de financement. <br>
                    Montant total des frais annexes :
                    <strong>{{ configuration.annexOptionsPrice ? utils.formatCentToEuro(configuration.annexOptionsPrice, true, true) : '-' }}</strong>
                  </p>
              </div>
              <div class="cell shrink"></div>
            </div>
            <div class="collapse-items annexes">
              <div class="item descriptif">
                <div class="head item-essentiel" :class="{ active: annexesTabs.includes(annexLot.lotId) }" @click="toggleArrayValue(annexesTabs, annexLot.lotId)">
                  <div class="option-item">
                    <arrow />
                    <strong>Lié au projet</strong>
                  </div>
                </div>
                <app-collapse class="ctn" v-model="annexesTabs" :name="annexLot.lotId">
                  <div>
                    <div
                      class="option option-descriptif grid-x"
                      v-for="option in annexOptions"
                      :key="option.optionId"
                    >
                      <div class="cell auto label">
                        <strong>{{ option.name }}</strong>
                        <small class="text-with-break">{{ option.description }}</small>
                      </div>
                      <div class="cell shrink price">
                        <strong v-if="option.type === 'BASE'">BASE</strong>
                        <strong v-else>{{ option.price > 0 ? '+ ' : '' }}{{ utils.formatCentToEuro(option.price, true, true) }}</strong>
                      </div>
                    </div>
                  </div>
                </app-collapse>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$route.params.tab === 'save'">
          <div class="tab-container tab-container-center">
            <img src="@/assets/img/arrow-config.svg" alt="fleche" class="intro">
            <h3>Enregistrer votre projet</h3>
            <p class="small-text">Vous retrouverez votre configuration dans votre compte à l’onglet “mes projets”</p>
            <div class="box">
              <form ref="saveForm" @submit.prevent="finalizeFormLoading ? null : saveConfiguration()">
                <div class="row">
                  <app-input label="Nom du projet" v-model="projectName" placeholder="PROJET_MAISON" color="default" type="text" required/>
                </div>
                <div class="row">
                  <app-select
                    v-if="customers && customersOptions"
                    label="Client"
                    :options="customersOptions"
                    v-model="customer.customerId"
                  />
                </div>
                <div class="row">
                  <app-select label="Statut du projet" :options="projectDraftStatuses" v-model="projectDraft.status" />
                </div>

                <div class="submit-btn"><app-button type="submit">{{ finalizeFormLoading ? 'Enregistrement en-cours ...' : 'Enregistrer' }}</app-button></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="spinner-container">
      <app-spinner />
    </div>
  </div>
</template>

<script>
// BO ONLY
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import projectDraftStatuses from '@/services/data/projectDraftStatuses.json';

// COMMON PUBLIC
import OptionCheckbox from '@/views/projectDraft/OptionCheckbox.vue';
import configurationApi from '@/services/api/configuration';
import versionMediaApi from '@/services/api/versionMedia';
import projectDraftApi from '@/services/api/projectDraft';
import categoryApi from '@/services/api/category';
import lotApi from '@/services/api/lot';
import themeApi from '@/services/api/theme';
import agencyApi from '@/services/api/agency';
import customerApi from '@/services/api/customer';
import memberApi from '@/services/api/member';

import lotSurveySpecificationApi from '@/services/api/lotSurveySpecification';

import utils from '@/services/utils/utils';
import auth from '@/services/auth';

import checked from '@/assets/img/checked.svg?inline';
import cancel from '@/assets/img/cancel.svg?inline';
import arrow from '@/assets/img/arrow.svg?inline';
import arrowLeft from '@/assets/img/arrow-left.svg?inline';

export default {
  name: 'project-draft-configuration',
  components: {
    Breadcrumb,
    OptionCheckbox,
    checked,
    cancel,
    arrow,
    arrowLeft,
  },
  data() {
    return {
      // BO ONLY
      customer: {
        customerId: null,
      },
      customers: null,
      customersOptions: null,
      isCustomerModalOpen: false,

      // COMMOn PUBLIC
      projectDraft: {
        name: null,
        status: null,
      },
      versionMedias: null,
      isLoading: true,

      packId: null,

      // Lightbox
      isLightboxVisible: false,
      lightboxIndex: 0,

      // Collapses (En savoir + et FAQ)
      openMoreTab: null,
      faqTab: 'question1',

      categoriesTabs: [],
      themesTabs: [],
      annexesTabs: [],

      configuration: null,

      lots: null,
      categories: null,
      themes: null,

      utils,

      selectedLot: null,
      me: null,
      brand: null,
      annexLot: null,
      annexThemes: null,
      annexOptions: null,
      lotSurveySpecifications: null,

      // on stock séparément pour pouvoir comparer avec les options de base
      optionsByThemes: {},
      selectedOptions: [],
      areExtensionsShown: false,

      firstExtensionCustomerWidth: null,
      secondExtensionCustomerWidth: null,
      isFirstExtensionValidated: false,
      roomsFirstExtensions: '',
      roomsSecondExtensions: '',
      isExtensionAuto: false,

      projectDraftStatuses,
      isLightboxBlueprintVisible: false,

      isCollapseCategoriesInit: false,
      isCollapseThemesInit: false,
      isCollapseAnnexesInit: false,

      finalizeFormLoading: false,
      isFormInvalid: false,

      // Project
      projectName: 'MON PROJET MAISON',

      // Scroll position content page (pour revenir à la précédente position depuis le retour de la page option)
      categoriesScrollPosition: 0,

      // mobile
      showPageContent: false,
    };
  },
  computed: {
    landZipCode() {
      if (this.configuration) {
        if (this.configuration.land) {
          return this.configuration.land.address.postalCodeInseeNumber.split('-')[0];
        }
        if (this.configuration.customLand) {
          return this.configuration.customLand.address.postalCodeInseeNumber.split('-')[0];
        }
      }
      return null;
    },
    pack() {
      if (this.configuration && this.configuration.packs.find((p) => p.isActive)) {
        const pack = this.configuration.packs.find((p) => p.isActive);
        let packData = null;
        if (pack.name === 'EKO') {
          packData = {
            title: pack.description,
            description: '<strong>Faites de sérieuses économies</strong> grâce au pack EKO. Vous réalisez vous-même certaines prestations comme par exemple les revêtements des sols. Vous avez accès <strong>à moins d’options pour optimiser votre budget</strong>.',
            name: pack.name,
            numberOfOptions: pack.options.length,
            optionsIncluded: ['Menuiseries extérieures PVC', 'Porte d’entrée Acier Blanche', 'Porte de garage Basculante'],
            optionsNotIncluded: ['Sanitaire à votre charge', 'Carrelage à votre charge'],
          };
        } else if (pack.name === 'ESSENTIEL') {
          packData = {
            title: pack.description,
            description: 'Le Pack Essentiel correspond à la <strong>configuration de base</strong> des maisons Alysia. Ce sont les ouvrages, finitions et <strong>prix affichés par défaut dans notre catalogue de maisons</strong>. Quand vous choisissez ce pack vous avez accès à de nombreuses options.',
            name: pack.name,
            numberOfOptions: pack.options.length,
            optionsIncluded: ['Menuiseries extérieures PVC', 'Porte d’entrée Acier Blanche', 'Porte de garage Basculante', 'Baignore acrylique', 'Carrelage 45x45 cm'],
            optionsNotIncluded: [],
          };
        } else if (pack.name === 'PREMIUM') {
          packData = {
            title: pack.description,
            description: 'Portes intérieures Design, revêtement de sols dans les chambres, carrelage 60x60cm... le Pack Premium sélectionne pour vous nos meilleures options. <strong>Vous accèdez à toutes les options du configurateur</strong>',
            name: pack.name,
            numberOfOptions: pack.options.length,
            optionsIncluded: ['Menuiseries extérieures ALU', 'Oscillo-battant', 'Porte d’entrée Acier Couleur Laqué', 'Porte de garage Sectionnelle', 'Motorisation porte de garage', 'Portes intérieures Design', 'Plafond + Isolation garage (hors maisons étages)', 'Ensemble thermostatique et tête de pluie', 'Baignoire acrylique', 'WC suspendu', 'Carrelage 60x60 cm', 'Plinthes en carrelage', 'Faïence Salle(s) de bain', 'Nettoyage Fin de chantier', 'Robinet extérieur (1U)'],
            optionsNotIncluded: [],
          };
        } else if (pack.name === 'PRIME') {
          packData = {
            title: pack.description,
            description: '<strong>Accédez à la propriété de votre maison neuve grâce à une savante combinaison d’optimisations permise par un mode constructif exclusif, un nombre limité d’options et des équipements adaptés. Tout en conservant la fameuse qualité Alysia.</strong>',
            name: pack.name,
            numberOfOptions: pack.options.length,
            optionsIncluded: ['Tuiles béton', 'Menuiseries extérieures PVC', 'Porte d’entrée Acier Blanche', 'Porte de garage Basculante'],
            optionsNotIncluded: ['Sanitaires à votre charge', 'Carrelage à votre charge'],
          };
        } else if (pack.name.includes('ACCESS')) {
          packData = {
            title: 'PACK ACCESS’',
            description: pack.description,
            name: pack.name,
            numberOfOptions: pack.options.length,
            optionsIncluded: ['Menuiseries extérieures PVC', 'Portes d’entrée Acier Blanche', 'Porte de garage Basculante', 'Baignoire ou receveur acrylique', 'Carrelage 45 x 45 cm'],
            optionsNotIncluded: [],
          };
        }
        return packData;
      }
      return null;
    },
  },

  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  beforeRouteUpdate(to, from, next) {
    // On utilise le beforerouteupdate car le scroll n'a pas de valeur dans le watch du router
    if (to.params.tab === 'options' && from.params.tab === 'categories') {
      this.categoriesScrollPosition = this.$refs.configuratorContent.scrollTop;
    }
    next();
  },
  watch: {
    optionsByThemes: {
      handler() {
        this.updateConfig();
      },
      deep: true,
    },
    firstExtensionCustomerWidth() {
      if (this.configuration && this.configuration.version && this.configuration.extensionsProperties && this.configuration.land) {
        const isAutomaticExtensionPossible = this.configuration.extensionsProperties.maxWidth - this.firstExtensionCustomerWidth > 0;

        if (isAutomaticExtensionPossible && (this.configuration.version.width + this.firstExtensionCustomerWidth) > (this.configuration.land.width - this.configuration.land.separationDistance)) {
          this.secondExtensionCustomerWidth = this.configuration.extensionsProperties.secondExtensionMaxWidth < this.configuration.extensionsProperties.maxWidth - this.firstExtensionCustomerWidth ? this.configuration.extensionsProperties.secondExtensionMaxWidth : this.configuration.extensionsProperties.maxWidth - this.firstExtensionCustomerWidth;
        }
      }
    },
    $route: {
      handler(newRoute) {
        if (this.$refs.configuratorContent) {
          // Si on est sur les categories, on se déplace avec la position de scroll stockée
          if (newRoute.params.tab === 'categories') {
            this.$nextTick(() => {
              this.$refs.configuratorContent.scrollTop = this.categoriesScrollPosition;
            });
          } else {
            this.$refs.configuratorContent.scrollTop = 0;
          }
        }
      },
    },
  },
  methods: {
    // BO ONLY
    async selectCustomer() {
      this.isLoading = true;
      this.customer = this.customers.find((customer) => customer.customerId === this.customer.customerId);

      if (this.customer && this.$route.query.customerId !== this.customer.customerId) {
        try {
          await this.$router.push({
            query: {
              ...this.$route.query,
              customerId: this.customer.customerId,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }

        await this.updateConfig();
      }

      this.isCustomerModalOpen = false;
      this.isLoading = false;
    },
    // Récupération du nom du client
    getCustomersNames(selectedCustomer) {
      return customerApi.getCustomersNames(selectedCustomer);
    },
    async getCustomers() {
      try {
        const customers = await customerApi.getAll(null, null, null, null, null, true);
        this.customers = customers.data;

        this.customersOptions = utils.formatOptions(
          this.customers,
          (option) => option.customerId,
          (option) => this.getCustomersNames(option),
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des clients',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCustomer() {
      try {
        if (this.$route.query && (this.$route.query.customerId || this.$route.query.projectDraftId)) {
          const customerId = this.projectDraft && this.projectDraft.customer ? this.projectDraft.customer.customerId : this.$route.query.customerId;
          this.customer = await customerApi.getById(customerId);

          if (this.$route.query.customerId !== this.customer.customerId) {
            try {
              await this.$router.push({
                query: {
                  ...this.$route.query,
                  customerId: this.customer.customerId,
                },
              });
            } catch (er) {
              if (er.name !== 'NavigationDuplicated') {
                throw er;
              }
            }
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du customer',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // COMMON PUBLIC
    previousLot() {
      if (this.lots && this.lots.length) {
        const index = this.lots.findIndex((lot) => lot.lotId === this.selectedLot);
        if (index - 1 >= 0) {
          this.selectedLot = this.lots[index - 1].lotId;
          this.$refs.configuratorContent.scrollTop = 0;
        }
      }
    },
    nextLot() {
      if (this.lots && this.lots.length) {
        const index = this.lots.findIndex((lot) => lot.lotId === this.selectedLot);
        if (index + 1 < this.lots.length) {
          this.selectedLot = this.lots[index + 1].lotId;
          this.$refs.configuratorContent.scrollTop = 0;
        }
      }
    },
    downloadPdf(allotment) {
      // télécharger le pdf du lotissement
      window.open(allotment.presentationPdf, '_blank');
    },
    // agrandissements
    firstExtensionMinSlider() {
      // A tester si on a un agrandissement qui devient automatique
      if (this.isExtensionAuto && (this.configuration.extensionsProperties.maxWidth - this.configuration.extensionsProperties.secondExtensionMaxWidth) > 0) {
        return Math.round((this.configuration.extensionsProperties.maxWidth - this.configuration.extensionsProperties.secondExtensionMaxWidth) * 100) / 100;
      }
      return 0;
    },
    firstExtensionMaxSlider() {
      if (((this.configuration.version.width + this.configuration.extensionsProperties.maxWidth) > (this.configuration.land.width - this.configuration.land.separationDistance)) && ((this.configuration.version.width + this.configuration.extensionsProperties.maxWidth) < this.configuration.land.width)) {
        return (this.configuration.land.width - this.configuration.land.separationDistance - this.configuration.version.width) > 0 ? this.configuration.land.width - this.configuration.land.separationDistance - this.configuration.version.width : 0;
      }
      return Math.round(this.configuration.extensionsProperties.firstExtensionMaxWidth * 100) / 100;
    },
    secondExtensionMaxSlider() {
      if ((this.configuration.version.width + this.firstExtensionCustomerWidth + this.configuration.extensionsProperties.secondExtensionMaxWidth) > (this.configuration.land.width - this.configuration.land.separationDistance) && ((this.configuration.version.width + this.firstExtensionCustomerWidth + this.configuration.extensionsProperties.secondExtensionMaxWidth) < this.configuration.land.width)) {
        return this.configuration.land.width - this.configuration.land.separationDistance - this.configuration.version.width - this.firstExtensionCustomerWidth;
      }
      if (this.configuration.extensionsProperties.secondExtensionMaxWidth < (this.configuration.extensionsProperties.maxWidth - this.firstExtensionCustomerWidth)) {
        return Math.round(this.configuration.extensionsProperties.secondExtensionMaxWidth * 100) / 100;
      }
      return Math.round((this.configuration.extensionsProperties.maxWidth - this.firstExtensionCustomerWidth) * 100) / 100;
    },
    async getBrand() {
      try {
        this.brand = await auth.getBrand();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    validateFirstExtension() {
      this.isFirstExtensionValidated = true;
    },
    editFirstExtension() {
      if (this.isFirstExtensionValidated) {
        this.$message.show({
          title: 'Avertissement',
          text: `En modifiant la surface des pièces du "${this.roomsFirstExtensions}" vous perdrez les modifications effectués sur l'agrandissement "${this.roomsSecondExtensions}"`,
          confirmText: 'Continuer',
          hasConfirm: true,
          cancelText: 'Annuler',
          hasCancel: true,
          onConfirm: () => {
            this.isFirstExtensionValidated = false;
            this.secondExtensionCustomerWidth = 0;
          },
        });
      }
    },
    async applyExtensions() {
      await this.updateConfig();
      await this.$router.push({
        params: {
          ...this.$route.params,
          tab: 'surfaces',
        },
        query: {
          ...this.$route.query,
        },
      });
    },
    optionChange(themeId, optionId) {
      const theme = this.themes.find((t) => t.themeId === themeId);

      // Si c'est un thème avec 1 option max, il faut enlever le reste des options pour n'en avoir toujours qu'une
      if (theme && theme.behavior === 'ONE_OPTIONAL') {
        if (this.optionsByThemes[themeId] && this.optionsByThemes[themeId].includes(optionId)) {
          this.optionsByThemes[themeId] = [optionId];
        } else {
          this.optionsByThemes[themeId] = null;
        }
      }

      this.updateConfig();
    },
    async updateConfig(packChange) {
      // update configuration
      this.isLoading = true;

      // Si on change de pack, il faut pas comparer avec les options précédentes
      if (!packChange) {
        await this.setSelectedOptions();
      }

      await this.getConfiguration();
      await this.getThemes();
      await this.getLots();
      await this.getCategories();

      await this.getOptionsByThemes();

      this.isLoading = false;
    },
    async getData() {
      // On récupère l'avant projet si on est dans de l'édition
      await this.getProjectDraft();

      await this.getBrand();
      await this.getConfiguration();
      await this.getThemes();
      await this.getLots();
      await this.getCategories();
      await this.getAgency();

      if (this.configuration && this.configuration.version) {
        await this.getVersionMedia();
        await this.getLotsSuveysSpecifications();
      }

      await this.getCustomers();

      if (this.$route.query && this.$route.query.customerId) {
        await this.getCustomer();
      }

      this.getOptionsByThemes();

      if (this.$route.params.tab === 'options') {
        // eslint-disable-next-line prefer-destructuring
        this.selectedLot = this.lots[0].lotId;
      }
    },

    async getAgency() {
      try {
        let postalCode = null;
        if (this.configuration && this.configuration.land) {
          postalCode = this.configuration.land.address.postalCodeInseeNumber;
        }
        if (this.configuration && this.configuration.customLand) {
          postalCode = this.configuration.customLand.address.postalCodeInseeNumber;
        }
        if (postalCode) {
          this.agency = await agencyApi.getByPostalCode(postalCode);

          if (this.agency) {
            const members = await memberApi.getByAgency(this.agency.agencyId);
            this.$set(this.agency, 'members', members);
          }
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getProjectDraft() {
      try {
        if (this.$route.params.projectDraftId) {
          this.projectDraft = await projectDraftApi.getById(this.$route.params.projectDraftId);

          await this.$router.push({
            query: {
              ...this.$route.query,
              landId: this.$route.query.landId || this.projectDraft.land.landId,
              versionId: this.$route.query.versionId || this.projectDraft.version.versionId,
              packId: this.projectDraft.pack.packId,
              customerId: this.$route.query.customerId || (this.projectDraft.customer ? this.projectDraft.customer.customerId : undefined),
              landWidth: this.$route.query.landWidth || (this.projectDraft.customerLand ? this.projectDraft.customerLand.width : undefined),
              landSurface: this.$route.query.landSurface || (this.projectDraft.customerLand ? this.projectDraft.customerLand.surface : undefined),
              landPrice: this.$route.query.landPrice || (this.projectDraft.customerLand ? this.projectDraft.customerLand.price : undefined),
              isMainDrainagePlanned: this.$route.query.isMainDrainagePlanned || (this.projectDraft.customerLand ? this.projectDraft.customerLand.isMainDrainagePlanned : undefined),
              postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber || (this.projectDraft.customerLand && this.projectDraft.customerLand.postalCodeInseeNumber ? this.projectDraft.customerLand.postalCodeInseeNumber : undefined),
              isServiced: this.$route.query.isServiced || (this.projectDraft.customerLand ? this.projectDraft.customerLand.isServiced : undefined),
              isClayZone: this.$route.query.isClayZone || (this.projectDraft.customerLand ? this.projectDraft.customerLand.isClayZone : undefined),
              separationDistance: this.$route.query.separationDistance || (this.projectDraft.customerLand ? this.projectDraft.customerLand.separationDistance : undefined),
            },
            params: {
              ...this.$route.params,
            },
          });

          this.selectedOptions = this.projectDraft.selectedOptions;

          this.firstExtensionCustomerWidth = this.projectDraft.extensionsProperties ? this.projectDraft.extensionsProperties.firstExtensionCustomerWidth : 0;
          this.secondExtensionCustomerWidth = this.projectDraft.extensionsProperties ? this.projectDraft.extensionsProperties.secondExtensionCustomerWidth : 0;

          this.projectName = this.projectDraft.name;
        }
      } catch (er) {
        if (er.response && er.response.status === 401) {
          this.$notification.show({ text: 'Votre session n\'est plus valide' });
          this.$router.push({ name: 'home' });
        } else if (er.name !== 'NavigationDuplicated') {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la récupération de l\'avant-projet',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    async getLotsSuveysSpecifications() {
      try {
        if (this.projectDraft && this.projectDraft.version && this.projectDraft.version.versionId) {
          this.lotSurveySpecifications = await lotSurveySpecificationApi.getByVersionId(this.projectDraft.version.versionId);
        } else if (this.configuration && this.configuration.version) {
          this.lotSurveySpecifications = await lotSurveySpecificationApi.getByVersionId(this.configuration.version.versionId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des spécifications de lots métrés',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async downloadProjectPdf() {
      if (!auth.isLoggedIn()) {
        this.historyTab = 'save';
        this.$router.push({
          params: {
            ...this.$route.params,
            tab: 'login',
          },
          query: {
            ...this.$route.query,
          },
        });
        this.$notification.show({ text: 'Vous devez être connecté pour enregistrer votre projet et télécharger votre descriptif PDF.' });
      } else {
        await this.saveConfiguration();

        document.getElementById('pdfLink').click();
      }
    },
    async getThemes() {
      try {
        const themes = await themeApi.getAll();
        let allOptions = null;

        if (this.configuration && this.configuration.options && this.configuration.options.length) {
          allOptions = this.configuration.options;
        }

        if (this.configuration && this.configuration.packs && this.configuration.packs.length) {
          this.configuration.packs = this.configuration.packs.map((pack) => {
            const options = pack.options && pack.options.length ? pack.options : null;
            if (options) {
              return {
                ...pack,
                options: options.filter((option) => {
                  const sameThemeOptions = options.filter((opt) => opt.theme.themeId === option.theme.themeId && (opt.type === 'STANDARD' || opt.type === 'BASE'));
                  return sameThemeOptions && sameThemeOptions.length;
                }),
              };
            }
            return {
              ...pack,
            };
          });
        }

        if (themes && themes.length && this.configuration && allOptions && allOptions.length) {
          this.themes = themes.filter((theme) => allOptions.find((option) => option.theme.themeId === theme.themeId && (option.type === 'STANDARD' || option.type === 'BASE')));
        } else {
          this.themes = null;
        }

        if (themes && themes.length && allOptions && allOptions.length) {
          this.annexOptions = allOptions.filter((option) => option.type === 'ANNEX' && option.isActive);

          if (this.annexOptions && this.annexOptions.length) {
            this.annexThemes = themes.filter((theme) => this.annexOptions.find((option) => option.theme.themeId === theme.themeId));
          }
        } else {
          this.annexOptions = null;
        }

        if (allOptions && allOptions.length) {
          this.configuration.options = allOptions;
        }
        if (this.themes && this.themes.length && !this.isCollapseThemesInit) {
          this.themesTabs = this.themes.map((theme) => theme.themeId);
          this.isCollapseThemesInit = true;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des themes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLots() {
      try {
        const lots = await lotApi.getAll(null);
        if (lots && lots.length) {
          this.lots = lots.filter((lot) => {
            let themeMatch = null;
            if (this.themes && this.themes.length) {
              themeMatch = this.themes.find((theme) => theme.lot.lotId === lot.lotId);
            }

            let lotSurveyMatch = null;
            if (this.configuration && this.configuration.lotsSurveysSpecifications && this.configuration.lotsSurveysSpecifications.length) {
              lotSurveyMatch = this.configuration.lotsSurveysSpecifications.find((lotSurveySpecification) => lotSurveySpecification.lot.lotId === lot.lotId && lotSurveySpecification.price > 0);
            }
            return themeMatch || lotSurveyMatch;
          });
        } else {
          this.lots = null;
        }
        if (lots && lots.length && this.themes && this.themes.length && this.annexThemes && this.annexThemes.length) {
          this.annexLot = lots.find((lot) => {
            const themeMatch = this.annexThemes.find((theme) => theme.lot.lotId === lot.lotId);
            return themeMatch;
          });
        } else {
          this.annexLot = null;
        }
        if (this.annexLot && !this.isCollapseAnnexesInit) {
          this.annexesTabs = [this.annexLot.lotId];
          this.isCollapseAnnexesInit = true;
        }

        // on ajoute les descriptions des lots métrés de la version
        if (this.lots && this.lots.length && this.lotSurveySpecifications && this.lotSurveySpecifications.length) {
          this.lots = this.lots.map((lot) => {
            // On récupère les lots surveys avec des descriptions
            const lotSurveys = this.lotSurveySpecifications.filter((lotSurvey) => lotSurvey.lot.lotId === lot.lotId && lotSurvey.description && lotSurvey.description.length > 0);
            return {
              ...lot,
              lotSurveys,
            };
          });
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCategories() {
      try {
        const categories = await categoryApi.getAll();

        if (categories && categories.length && this.lots && this.lots.length) {
          this.categories = categories.filter((category) => {
            const lotMatch = this.lots.find((lot) => lot.categoryId === category.categoryId);
            return lotMatch;
          });
        } else {
          this.categories = null;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async packChange() {
      this.isCollapseThemesInit = false;
      this.isCollapseCategoriesInit = false;
      this.isCollapseAnnexesInit = false;

      // On reset les collapses categories pour les fermer
      this.categoriesTabs = [];

      // On reset la postion de scroll stockée
      this.categoriesScrollPosition = 0;

      // On reset les options sélectionnées par le client s'il change de pack
      this.selectedOptions = null;

      // Au clic, on maj la config et on part directement sur la page catégories
      try {
        await this.$router.push({
          query: {
            ...this.$route.query,
            packId: this.packId,
          },
          params: {
            ...this.$route.params,
            tab: 'categories',
          },
        });
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          throw er;
        }
      }

      await this.updateConfig(true);
    },
    async getConfiguration() {
      try {
        if (!this.$route.query.versionId) {
          this.$message.show({
            title: 'Erreur',
            text: 'La version n\'est pas disponible',
            cancelText: 'Ok',
            hasCancel: true,
          });
          let landChoiceError = this.configuration && this.configuration.land && this.configuration.land.landId ? 'land' : null;
          if (!landChoiceError) {
            landChoiceError = this.configuration
              && this.configuration.customerLand
              && this.configuration.customerLand.price
              && this.configuration.customerLand.width
              ? 'custom-land' : 'no-land';
          }

          this.$router.push({
            name: 'customers-projects-drafts-new',
            query: {
              customerId: this.$route.query.customerId,
              postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber,
              modelId: this.$route.query.modelId,
              landChoice: landChoiceError,
              landWidth: this.$route.query.landWidth,
              landSurface: this.$route.query.landSurface,
              landPrice: this.$route.query.landPrice,
              isMainDrainagePlanned: this.$route.query.isMainDrainagePlanned,
              isServiced: this.$route.query.isServiced,
              isClayZone: this.$route.query.isClayZone,
              emptyLand: this.$route.query.emptyLand,
            },
          });
        } else {
          let customerLand = null;
          if (this.$route.query.landWidth && this.$route.query.landPrice) {
            customerLand = {
              width: this.$route.query.landWidth ? parseFloat(this.$route.query.landWidth) : 0,
              surface: this.$route.query.landSurface ? parseFloat(this.$route.query.landSurface) : 0,
              price: this.$route.query.landPrice ? parseInt(this.$route.query.landPrice, 10) : 0,
              isMainDrainagePlanned: (this.$route.query.isMainDrainagePlanned === true || this.$route.query.isMainDrainagePlanned === 'true'),
              isServiced: (this.$route.query.isServiced === true || this.$route.query.isServiced === 'true'),
              isClayZone: (this.$route.query.isClayZone === true || this.$route.query.isClayZone === 'true'),
              postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber,
            };
          } else if (!this.$route.query.landId) {
            customerLand = {
              width: 1000000,
              surface: 0,
              price: 0,
              postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber,
              separationDistance: 0,
            };
          }

          if (!this.$route.query.landId && !customerLand.postalCodeInseeNumber && this.projectDraft && this.projectDraft.projectDraftId) {
            customerLand = this.projectDraft.customerLand;
          }

          if (customerLand && !customerLand.postalCodeInseeNumber) {
            const undefinedPostalCode = new Error('Le code postal/INSEE n\'est pas disponible');
            undefinedPostalCode.name = 'ErrorUndefinedPostalCode';
            throw undefinedPostalCode;
          }

          this.configuration = await configurationApi.getConfiguration(
            this.brand.brandId, {
              customerLand,
              landId: this.$route.query.landId,
              versionId: this.$route.query.versionId,
              packId: this.$route.query.packId,
              customerGenerated: false,
              selectedOptions: this.selectedOptions ? this.selectedOptions : null,
              extensionsProperties: (this.firstExtensionCustomerWidth || this.secondExtensionCustomerWidth) ? {
                firstExtensionCustomerWidth: this.firstExtensionCustomerWidth,
                secondExtensionCustomerWidth: this.secondExtensionCustomerWidth,
              } : null,
            },
          );

          if (this.configuration.packs.length === 1) {
            await this.$router.push({
              query: {
                ...this.$route.query,
                packId: this.configuration.packs[0].packId,
              },
            });
          }
          const pack = this.configuration.packs.find((selectedPack) => selectedPack.isActive);

          this.packId = pack ? pack.packId : null;

          // On met les valeurs dans les agrandissements si c'est des valeurs du client, ou bien si c'est des agrandissements automatiques
          if (this.configuration.extensionsProperties && this.configuration.extensionsProperties.isActive) {
            this.firstExtensionCustomerWidth = this.configuration.extensionsProperties.selectedByCustomer
              ? this.configuration.extensionsProperties.firstExtensionCustomerWidth : this.configuration.extensionsProperties.firstExtensionMaxWidth;

            this.secondExtensionCustomerWidth = this.configuration.extensionsProperties.selectedByCustomer
              ? this.configuration.extensionsProperties.secondExtensionCustomerWidth : this.configuration.extensionsProperties.maxWidth - this.configuration.extensionsProperties.firstExtensionMaxWidth;
          }

          if (this.configuration.version.surfaces) {
            // Récupération des noms des pièces dans les agrandissements
            this.roomsFirstExtensions = this.configuration.version.surfaces.reduce((a, surface) => {
              if (surface.firstExtensionDepth > 0 && a && a.length) {
                return `${a} + ${surface.room.name}`;
              }
              if (surface.firstExtensionDepth > 0) {
                return surface.room.name;
              }
              return a;
            }, '');

            this.roomsSecondExtensions = this.configuration.version.surfaces.reduce((a, surface) => {
              if (surface.secondExtensionDepth > 0 && a && a.length) {
                return `${a} + ${surface.room.name}`;
              }
              if (surface.secondExtensionDepth > 0) {
                return surface.room.name;
              }
              return a;
            }, '');

            // récupération des surfaces des pièces des agrandissement
            this.configuration.version.surfaces = this.configuration.version.surfaces.map((surface) => {
              let extensionSurface = surface.area;

              if (this.firstExtensionCustomerWidth && surface.firstExtensionDepth) {
                extensionSurface += this.firstExtensionCustomerWidth * surface.firstExtensionDepth;
              }
              if (this.secondExtensionCustomerWidth && surface.secondExtensionDepth) {
                extensionSurface += this.secondExtensionCustomerWidth * surface.secondExtensionDepth;
              }

              return {
                ...surface,
                extensionSurface: extensionSurface !== surface.area ? extensionSurface : null,
              };
            });

            if (!this.isFirstExtensionValidated) {
              this.isFirstExtensionValidated = !this.configuration.version.isFirstExtensionActive || (this.configuration.extensionsProperties.firstExtensionMaxWidth < 0.1);
            }
            this.isExtensionAuto = (this.configuration.land.separationDistance && (this.configuration.version.width >= (this.configuration.land.width - this.configuration.land.separationDistance))) && (this.configuration.version.width < this.configuration.land.width);
          }

          let postalCodeInseeNumber = this.configuration.land && this.configuration.land.landId ? this.configuration.land.address.postalCodeInseeNumber : null;
          postalCodeInseeNumber = !postalCodeInseeNumber && this.configuration.customerLand ? this.configuration.customerLand.postalCodeInseeNumber : postalCodeInseeNumber;

          await this.$router.push({
            query: {
              ...this.$route.query,
              postalCodeInseeNumber,
            },
            params: {
              ...this.$route.params,
            },
          });
        }
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          this.$message.show({
            title: 'Erreur',
            text: `Il y a eu un problème lors de la récupération de la configuration. ${er.name === 'ErrorUndefinedPostalCode' ? er.message : ''}`,
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    async getVersionMedia() {
      try {
        const versionMedias = await versionMediaApi.getAll(this.configuration.version.versionId);
        const exteriorImages = versionMedias.filter((media) => media.type === 'EXTERIOR_IMAGE');
        const blueprints = versionMedias.filter((media) => media.type === 'BLUEPRINT');
        const mainImages = versionMedias.filter((media) => media.type === 'MAIN_IMAGE');
        const secondaryImages = versionMedias.filter((media) => media.type === 'SECONDARY_IMAGE');

        this.versionMedias = []
          .concat(exteriorImages || [])
          .concat(blueprints || [])
          .concat(mainImages || [])
          .concat(secondaryImages || []);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des images des versions',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    setSelectedOptions() {
      // Set selectedOptions for configure
      let options = [];
      if (this.themes && this.themes.length) {
        this.themes.forEach((theme) => {
          // la ou les options activées dans le configurateur
          let themeOptions = this.optionsByThemes[theme.themeId];

          // les options de la configuration
          let configOptions = null;
          if (this.configuration.options && this.configuration.options.length) {
            configOptions = this.configuration.options.filter((option) => option.theme.themeId === theme.themeId);
          }

          if (themeOptions && !Array.isArray(themeOptions) && themeOptions.length) {
            themeOptions = [themeOptions];
          } else if (!themeOptions || !themeOptions.length) {
            themeOptions = [];
          }

          // on compare, pour voir si le client à fait des changements
          // on compare les thèmes pour voir si quelque chose à changé
          let themeChanged = false;

          if (configOptions && configOptions.length) {
            configOptions.forEach((option) => {
              const match = themeOptions.find((themeOption) => themeOption === option.optionId);
              if (!themeChanged && ((option.isActiveDefault && !match) || (!option.isActiveDefault && match))) {
                themeChanged = true;
              }
            });
          }

          // Si quelque chose a changé, on ajoute toutes les options
          let parseOptions = [];
          if (themeChanged && configOptions && configOptions.length) {
            parseOptions = configOptions.map((option) => {
              const match = themeOptions.find((themeOption) => themeOption === option.optionId);
              return {
                optionId: option.optionId,
                isActive: !!match,
              };
            });

            options = options.concat(parseOptions);
          }
        });
      }
      this.selectedOptions = options;
    },
    getOptionsByThemes() {
      // get optionsByThemes from configuration
      if (this.configuration && this.configuration.options.length && this.themes && this.themes.length) {
        this.themes.forEach((theme) => {
          const themeOptions = this.configuration.options.filter(
            (option) => option.theme.themeId === theme.themeId && option.isActive,
          );
          const optionIds = themeOptions.map((option) => option.optionId);
          if (theme.behavior === 'ONE_REQUIRED') {
            if (themeOptions && themeOptions.length) {
              // eslint-disable-next-line prefer-destructuring
              this.optionsByThemes[theme.themeId] = optionIds[0];
            }
          } else {
            this.optionsByThemes[theme.themeId] = optionIds;
          }
        });
      }
    },
    // Pour retirer ou ajouter un element d'un tableau (pour les tabs)
    toggleArrayValue(array, value) {
      if (array.indexOf(value) === -1) {
        array.push(value);
      } else {
        array.splice(array.indexOf(value), 1);
      }
    },
    displayExtensions() {
      this.areExtensionsShown = true;
    },
    getExtensionPrice() {
      const habitableSurface = this.configuration.version.surfaces.reduce(
        (a, surface) => (
          surface.room.type === 'HABITABLE' ? a + (surface.firstExtensionDepth * this.firstExtensionCustomerWidth) + (surface.secondExtensionDepth * this.secondExtensionCustomerWidth) : a
        ),
        0,
      );

      const nonHabitableSurface = this.configuration.version.surfaces.reduce(
        (a, surface) => (
          surface.room.type === 'NON_HABITABLE' ? a + (surface.firstExtensionDepth * this.firstExtensionCustomerWidth) + (surface.secondExtensionDepth * this.secondExtensionCustomerWidth) : a
        ),
        0,
      );

      const carportSurface = this.configuration.version.surfaces.reduce(
        (a, surface) => (
          surface.room.type === 'CARPORT' ? a + (surface.firstExtensionDepth * this.firstExtensionCustomerWidth) + (surface.secondExtensionDepth * this.secondExtensionCustomerWidth) : a
        ),
        0,
      );

      return (
        (habitableSurface || 0) * (this.brand.habitableExtensionPrice ? this.brand.habitableExtensionPrice : 0)
        + (nonHabitableSurface || 0) * (this.brand.nonHabitableExtensionPrice ? this.brand.nonHabitableExtensionPrice : 0)
        + (carportSurface || 0) * (this.brand.carportExtensionPrice ? this.brand.carportExtensionPrice : 0));
    },
    changeLand(landChoice) {
      this.$message.show({
        title: 'Voulez-vous changer votre terrain ?',
        text: 'Si vous avez personnalisé la configuration de votre maison et si vous souhaitez la conserver, enregistrez votre projet avant de changer de terrain.',
        confirmText: 'Changer de terrain',
        hasConfirm: true,
        cancelText: 'Annuler ou enregister mon projet',
        hasCancel: true,
        onConfirm: () => {
          try {
            // Si on est dans un avant projet
            if (this.$route.params.projectDraftId) {
              this.$router.push({
                name: 'customers-projects-drafts-projectDraftId-edit',
                params: {
                  projectDraftId: this.$route.params.projectDraftId,
                },
                query: {
                  ...this.$route.query,
                  landChoice,
                  openLandChoice: true,
                  modelId: this.configuration.version.model.modelId,
                },
              });
            } else {
              // Si on est pas dans un avant-projet
              this.$router.push({
                name: 'customers-projects-drafts-edit',
                query: {
                  ...this.$route.query,
                  landChoice,
                  openLandChoice: true,
                  modelId: this.configuration.version.model.modelId,
                },
              });
            }
          } catch (er) {
            if (er.name !== 'NavigationDuplicated') {
              throw er;
            }
          }
        },
      });
    },
    async save() {
      const selected = this.selectedOptions ? this.selectedOptions.map((option) => option.optionId) : [];
      const options = this.configuration.options.filter((option) => selected.includes(option.optionId) || option.isActive);
      // Si c'est un nouveau projet ou si on est sur un projet qui ne nous appartient pas
      if (!this.$route.params.projectDraftId) {
        try {
          this.projectDraft = await projectDraftApi.create({
            name: this.projectName ? this.projectName : 'Nouveau projet',
            landId: this.configuration.land.landId,
            customerId: this.customer && this.customer.customerId ? this.customer.customerId : null,
            versionId: this.configuration.version.versionId,
            customerLand: this.configuration.customerLand,
            packId: this.configuration.packs.find((p) => p.isActive).packId,
            extensionsProperties: this.configuration.extensionsProperties,
            totalPrice: this.configuration.totalPrice,
            totalSurface: this.configuration.totalSurfaceHabitable,
            landPrice: this.configuration.customerLand ? this.configuration.customerLand.price : this.configuration.land.price,
            initialPrice: this.configuration.initialPrice,
            selectedOptions: options,
            surfaces: this.configuration.surfaces,
            status: this.projectDraft.status,
          });
          this.$notification.show({ text: 'Votre projet est ajouté !' });
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de la création de l\'avant-projet',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
        }
      } else {
        try {
          this.projectDraft = await projectDraftApi.update(this.$route.params.projectDraftId, {
            name: this.projectName ? this.projectName : 'Nouveau projet',
            landId: this.configuration.land.landId,
            versionId: this.configuration.version.versionId,
            customerLand: this.configuration.customerLand,
            packId: this.configuration.packs.find((p) => p.isActive).packId,
            extensionsProperties: this.configuration.extensionsProperties,
            totalPrice: this.configuration.totalPrice,
            totalSurface: this.configuration.totalSurfaceHabitable,
            landPrice: this.configuration.customerLand ? this.configuration.customerLand.price : this.configuration.land.price,
            initialPrice: this.configuration.initialPrice,
            selectedOptions: options,
            surfaces: this.configuration.surfaces,
            status: this.projectDraft.status,
          });
          this.$notification.show({ text: 'Votre projet est mis à jour !' });
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de la mise à jour du projet',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
        }
      }
    },
    async saveConfiguration() {
      if (this.$refs.saveForm && !this.$refs.saveForm.checkValidity()) {
        return;
      }
      this.finalizeFormLoading = true;
      try {
        await this.save();

        await this.$router.push({
          name: 'customers-projects-drafts-projectDraftId-configuration',
          params: {
            ...this.$route.params,
            projectDraftId: this.projectDraft.projectDraftId,
          },
          query: {
            ...this.$route.query,
          },
        });
        this.finalizeFormLoading = false;
      } catch (er) {
        if (er.name !== 'NavigationDuplicated' && !er.message.includes('navigation guard')) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la mise à jour du projet',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.finalizeFormLoading = false;
      }
    },
    openShare() {
      this.$socialShares.show({});
    },
  },
};
</script>

<style lang="sass" scoped>
.project-draft-configuration
  margin-top: 0px
  border-top: 1px solid $line
  .body
    @include screen
  .header
    @include header
    padding-bottom: 30px
    .project-infos
      @include tag-danger
      display: block
      width: 100%
      padding: 1.5rem
      font-size: 90%
      border-radius: 4px
  .row
    @include row
  .card
    @include card
    border-radius: 8px
  .breadcrumbs
    @include breadcrumbs
  a
    text-decoration: none !important

  .configurator
    display: flex
    .sidebar
      background: white
      height: calc(100vh - 175px)
      overflow-y: auto
      .sidebar-cell
        padding: 1.7rem 1.4rem 1.35rem 1.2rem
        border-bottom: 1px solid #cfcfcf
      .head
        display: flex
        flex-direction: column
        align-items: flex-start
        padding-top: 1rem
        padding-bottom: 1rem
        line-height: 1.7rem
        row-gap: 8px
        span
          @include subtitle
          color: #222222
          font-weight: 900
          font-size: 1.6rem
          line-height: 1.5
        a
          @include link
          color: $blue-button
          border: 1px solid $blue-button
          border-radius: 16px
          padding: 4px 12px
          background-color: transparent
          outline: 2px solid transparent
          transition: all 0.3s ease-out
          &:hover
            transition: all 0.3s ease-in
            color: $white
            background-color: $blue-button
          &:active
            outline: 2px solid rgba($primary, 0.5)
      .nav
        // width: 33vw
        // min-width: 380px
        .actions
            display: flex
            flex-direction: column
            justify-content: space-between
            align-items: center
            padding: 2rem
            #pdfLink
              position: fixed
              z-index: -1
              top: -100vh
              left: -100vw
            .btn-action
              margin: 0.5rem 0
              width: 100%
              height: auto
              border-radius: 2rem
              font-weight: 700
              text-align: center
              cursor: pointer
              outline: 2px solid transparent
              img
                display: inline-block
                padding-right: 0.2rem
                vertical-align: -6px
              &.btn-light
                padding: 0.75rem 0
                background: $white
                color: $error
                border: 1px solid $error
                transition: all 0.3s ease-in
                &:hover
                  background: $error
                  color: $white
                  border: 1px solid $error
                  transition: all 0.3s ease-out
                &:active
                  outline: 2px solid rgba($error, 0.5)
              &.btn-strong
                padding: 0.5rem 0
                margin-bottom: 2rem
                background: $primary
                color: $white
                font-size: $font-sm
                transition: all 0.3s ease-in
                &:hover
                  transition: all 0.3s ease-out
                  background-color: darken($primary, 5%)
                &:active
                  outline: 2px solid rgba($primary, 0.5)
                small
                  display: block
                  color: white
                  font-size: $font-3xs
                  font-weight: 400
              &.btn-lighter
                display: flex
                justify-content: center
                align-items: center
                padding: 0
                background: $white
                color: $blue-button
                border: none
                font-weight: 400
                font-size: $font-xs
                transition: all 0.3s ease-in
                svg
                  margin-right: 0.5rem
                  path:first-child
                    fill: $blue-button
                  path:last-child
                    stroke: $blue-button
                &:hover
                  color: darken($blue-button, 10%)
                  svg
                    path:first-child
                      fill: darken($blue-button, 10%)
                    path:last-child
                      stroke: darken($blue-button, 10%)
        .item
          align-items: center
          color: $black
          line-height: 1
          transition: all 0.3s ease-in-out
          &:hover
            background: lighten($primary, 52%)
          &.router-link-active
            position: relative
            box-sizing: border-box
            border-left: 10px solid $primary
            background: lighten($primary, 50%)
            &::after
              content: " "
              position: absolute
              top: 50%
              right: 0
              display: inline-block
              margin-top: -5px
              height: 0
              width: 0
              border-top: 9px solid transparent
              border-right: 10px solid rgba(32, 32, 32, 0.8)
              border-bottom: 9px solid transparent
        strong
          @include body-bold
          color: $subtitle
        small
          color: $body-color
          @include infos
        .highlight
          @include body-bold
          color: $primary

      .total
        display: flex
        justify-content: space-between
        .price
          color: $black
    .content
      flex: 1
      background: black url('/img/configuration/config-background.jpg') center center
      background-attachment: fixed
      color: white
      height: calc(100vh - 175px)
      overflow-y: auto
      background-position: top
      background-repeat: no-repeat
      object-fit: cover
      background-size: cover
      > div
        min-height: calc(100vh - 135px)
        padding: 2rem 3rem
        backdrop-filter: blur(4px)
        background-color: rgba(0, 0, 0, 0.4)
      h4
        @include infos
        margin-top: 0.2rem
        margin-bottom: 1.2rem
        padding: 0
        color: white
        font-size: 1.1rem
      h4.return-link
        @include infos
        margin-top: 0.2rem
        color: white
        padding: 5px 20px
        border-radius: 20px
        background-color: $blue-button
        display: inline-flex
        border-bottom: none
      .app-spinner
        margin: calc(50vh - 120px) auto 0
      .change-pack
        display: inline-block
        background-color: $blue-button
        border-radius: 16px
        padding: 8px 12px
        color: $white
        outline: 2px solid transparent
        transition: all 0.3s ease-in
        &:hover
          transition: all 0.3s ease-out
          background-color: $blue-button
        &:active
          outline: 2px solid rgba($primary, 0.3)
      .tab-container
        h3
          color: $white
        > p, .text-with-break
          color: $white
          margin-bottom: 2rem
        .card-container
          background-color: $white
          padding: 28px
          color: $black
          border-radius: 20px
          margin-bottom: 28px
        h2
          padding: 16px 0
        .small-text
          @include body
          color: $line
        &.tab-container-center
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          h3
            font-size: 36px
          p
            text-align: center
          .box
            margin-top: 2rem
            width: 100%
            max-width: 600px
            background: white
            border-radius: 10px
            form
              padding: 2.5rem 3rem
              display: flex
              flex-direction: column
            label.big
              display: block
              margin: 2rem 0 1rem
              color: $body
              font-size: 18px
              font-weight: bold
            .form-line
              margin: 1rem 0
              .cell:first-child
                margin-right: 0.5rem
              .cell:last-child
                margin-left: 0.5rem
            .app-input ~ .app-input
              margin-top: 1.5rem
            .app-textarea
              margin-top: 1.5rem
            .app-radio
              margin-right: 1.5rem
            .forgotten-password-btn
              margin-left: auto
              font-size: 60%
            .app-button
              margin: 2rem auto 0
              width: 100%
              h4
                margin: 0
          .terms
            margin: 2rem 0 0 0
            text-align: left
            font-size: 11px
            color: $body
            a
              color: $primary
              text-decoration: underline
              font-size: 11px
              font-weight: bold
        h3
          color: white
          margin: 0 0 1.3rem
          font-size: 1.8rem
        p
          @include small-body
          &.footnote
            margin-top: 30px
            max-width: initial
            font-weight: 300
        .pack-description
          margin-bottom: 1.5rem
          p
            font-size: 12px
            line-height: 24px
            font-zeight: 400
          h3
            color: $black
        .pack-content
          display: grid
          grid-template-columns: repeat(2, 1fr)
          grid-template-rows: 1fr
          column-gap: 1rem
          @media (max-width: 768px)
            grid-template-rows: 0.5fr 1fr
            grid-template-columns: 1fr
        .pack-items,
        .pack-title-container
          display: flex
          justify-content: center
          align-items: center
        .pack-title-container
          flex-direction: column
        .pack-pack
          font-weight: 300
          font-size: $font-sm
          margin-bottom: 0.75rem
        .pack-title
          color: $black
          font-weight: 700
          font-size: $font-lg
          position: relative
          margin-bottom: 1.5rem
          &::after
            content: ""
            width: 3rem
            height: 0.5rem
            position: absolute
            bottom: -12px
            left: 50%
            transform: translate(-50%, 0)
        .pack-options-eko,
        .pack-options-premium,
        .pack-options-prime,
        .pack-options-essentiel,
        .pack-options-access
          font-weight: 700
          font-size: $font-xxs
          text-align: center
        .pack-options-eko
          color: $eko
        .pack-options-premium
          color: $premium
        .pack-options-essentiel
          color: $essentiel
        .pack-options-prime
          color: $prime
        .pack-options-access
          color: $access
        .pack-underline-eko::after
          background-color: $eko
        .pack-underline-premium::after
          background-color: $premium
        .pack-underline-essentiel::after
          background-color: $essentiel
        .pack-underline-prime::after
          background-color: $prime
        .pack-underline-access::after
          background-color: $access
        .checked-eko
          path
            stroke: $eko
        .checked-essentiel
          path
            stroke: $essentiel
        .checked-premium
          path
            stroke: $premium
        .checked-prime
          path
            stroke: $prime
        .checked-access
          path
            stroke: $access
        .cancel
          stroke: $body-color
        .options-not-included,
        .options-included
          svg
            width: 16px
            height: 16px
        .pack-items
          display: flex
          flex-direction: column
          justify-content: center
          align-items: baseline
          ul
            padding: 0
            li
              display: flex
              align-items: center
              margin-bottom: 0.5rem
              font-weight: 500
              svg
                margin-right: 0.5rem
        .version-media
          display: flex
          margin-bottom: 2rem
          cursor: pointer
          transition: all 0.4s ease-in
          object-fit: contain
          box-shadow : 0px 0px 40px 10px rgba(0, 0, 0, 0.7)
        .lot
          display: flex
          background-color: #25252A
          padding: 1.5rem 1rem
          border-radius: 20px 20px 0 0
          .cell.shrink
            display: flex
            align-items: center
            width: 200px
            height: 200px
            margin-right: 1rem
            img
              width: 100%
              height: 100%
              object-fit: cover
              border-radius: 14px
        .surfaces-card
          margin: 2rem 0
          background-color: white
          border-radius: 8px
          &.disabled
            opacity: 0.5
            cursor: pointer
          .surfaces-header
            .title
              font-weight: 600
              text-transform: uppercase
              padding: 16px 40px 16px 16px
              color: $subtitle
          p
            @include body
            color: $subtitle
            margin: 1rem
          .price
            @include body-bold
            color: $black
            &.extended
              color: $primary
          .surface:not(:first-child)
            border-top: 1px solid $line
          .slider-container
            margin: 1rem
            p:first-child
              margin: 1rem 0 0 0
            p:not(:first-child)
              font-weight: 600
            p
              margin: 0
              color: $body-color
              @include label
              text-align: center
            .extension-value
              margin-top: 1rem
              text-align: center
              width: 100%
              display: block
              @include cta
              color: $primary
        .extension-link
          border: white 1px solid
          border-radius: 8px
          padding: 2rem
          margin: 2rem 0
          .cell
            display: flex
            align-items: center
          &.light
            background-color: white
            h3
              color: black
          h3
            margin: 0
            font-size: 1.4rem
        .margin-cards:first-child
          margin-right: 10px
        .margin-cards:last-child
          margin-left: 10px
        .margin-cards
          margin-bottom: 0
          text-align: center
          h1
            font-size: 45px
            font-weight: 500
            color: $warning
            text-align: center
          strong
            font-size: inherit
            color: black
          .right h1
            font-size: 45px
            font-weight: 500
            color: $primary
        .extension-title
          p
            @include infos
            color: $body-color
            margin: 24px 24px 0 24px
            padding: 0 16px
          .rooms p
            @include body-bold
            color: black
            padding: 0 10px
        p.extension-price, p.extension-surface
          color: $warning
          padding: 0 1rem 0 1rem
          @include body-bold
          margin-top: 0
        p.extension-width
          padding: 0 1rem 0 1rem
          margin: 0 0 24px 0
        .extension-price
          text-align: right
        .slider-container
          padding: 0 1rem 0 1rem
        .room:not(:last-child)
          border-bottom: 1px solid $line
        .room
          margin: 0 1rem 0 1rem
          .area p, .max-area p
            font-weight: 500
            @include infos
          .name p
            @include cta
            text-align: center
            color: black
            span
              @include cta
              color: $primary
          .max-area p
            text-align: right
        .footer
          padding: 2rem
          .cell
            display: flex
            align-items: center
          p
            @include label
            color: $body-color
            margin: 0
        .submit
          margin: 2rem
          @include centered-container
          button
            width: 100%
    .packs
      display: flex
      justify-content: center
      align-items: center
      padding-top: 1rem
      .pack
        display: flex
        flex-direction: column
        align-items: center
        margin: 0 0.75rem
        padding: 4rem 0.5rem 0.5rem 0.5rem
        width: 180px
        background: white
        overflow: hidden
        cursor: pointer
        border-radius: 1.5rem
        transition: opacity 0.45s ease-in-out, transform 0.2s linear
        &:hover
          transform: scale(1.02)
        &.active
          opacity: 1
          transform: scale(1.1)
          box-shadow: 0 0 1.5rem rgba($black, 0.9)
          z-index: 1

        .title
          display: flex
          flex-direction: column
          align-items: center
          font-weight: 900
          font-size: 1.6rem
          line-height: 1.5
          color: $black
          small
            font-weight: 300
            font-size: $font-xs
            color: $body-color
          &::after
            content: ""
            width: 3rem
            border-bottom: 0.5rem solid $eko
        .description
          display: flex
          flex-direction: column
          align-items: center
          padding: 2rem 1.7rem
          @include small-body
          color: $subtitle
          text-align: center
          small
            @include label
            color: $body-color
        .choice
          display: flex
          flex-direction: column
          align-items: center
          margin-bottom: 4rem
          span
            font-weight: 500
            font-size: $font-sm
            color: $eko
          .app-radio
            opacity: 0
        .config-btn
          align-self: stretch
          padding: 25px 20px
          text-align: center
          font-weight: 400
          border-radius: 20px
          font-size: 18px
          background: linear-gradient(rgba($eko, 0.8), $eko)
          color: white
          border: none
          cursor: pointer

        &#ESSENTIEL
          .title::after
            border-color: $essentiel
          .choice span
            color: $essentiel
          .config-btn
            background: linear-gradient(rgba($essentiel, 0.8), $essentiel)
        &#PREMIUM
          .title::after
            border-color: $premium
          .choice span
            color: $premium
          .config-btn
            background: linear-gradient(rgba($premium, 0.8), $premium)
        &#PRIME
          .title::after
            border-color: $prime
          .choice span
            color: $prime
          .config-btn
            background: linear-gradient(rgba($prime, 0.8), $prime)
        &[id^="ACCESS"]
          .title::after
            border-color: $access
          .choice span
            color: $access
          .config-btn
            background: linear-gradient(rgba($access, 0.8), $access)
    button.more
      display: block
      margin: 5rem auto 0rem
      padding: 1rem
      width: 100%
      max-width: 660px
      font-weight: 900
      color: white
      border: 1px solid white
      border-radius: 2rem
      background: transparent
      font-size: $font-xs
      cursor: pointer

    .more-informations
      display: block
      margin: 0 auto
      width: 100%
      max-width: 640px
      h5
        margin: 3rem 0 2rem
        @include subtitle
        color: $medium-bg
      p
        @include small-body
        color: $medium-bg
      .faq
        .item
          padding: 1rem 1.3rem 0.9rem
          border: 1px solid #757575
          border-bottom: 0
          &:first-child
            border-radius: $global-border-radius $global-border-radius 0 0
          &:last-child
            border-bottom: 1px solid #757575
            border-radius: 0 0 $global-border-radius $global-border-radius
          .question
            position: relative
            @include body-bold
            color: white
            cursor: pointer
            padding-right: 16px
            display: flex
            &:after
              content: "↓"
              position: absolute
              right: 0
              cursor: pointer
              transition: all 0.3s ease-in-out
            &.active:after
              transform: rotate(-180deg)
          .answer
            color: #CFCFCF
            @include small-body
            color: $line

    .collapse-items
      margin: 0 0 24px 0
      border-radius: $global-border-radius
      &.lots
        .option
          strong
            font-weight: 500
            font-size: 1.7rem
          small
            color: $secondary
      &.lot-options, &.annexes
        .option
          padding: 1rem 2rem 1rem 1rem
          small
            color: lighten($body-color, 30%)
            font-weight: normal
      .item-eko
        background-color: $eko
      .item-essentiel
        background-color: $essentiel
      .item-premium
        background-color: $premium
      .item-prime
        background-color: $prime
      .item-access
        background-color: $access
      .items-header
        background-color: $white
        border-radius: $global-border-radius $global-border-radius 0 0
        padding: 28px
        h3
          color: $black
      .pack-total
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        margin-bottom: 1.5rem
      .pack-total-options
        color: $black
      .pack-price
        font-weight: 700 !important
        font-size: $font-lg !important
      .option-eko
        color: $eko
      .option-premium
        color: $premium
      .option-essentiel
        color: $essentiel
      .option-prime
        color: $prime
      .option-access
        color: $access
      .option-customising
        font-size: $font-md !important
      .descriptif
        background-color: $white
      .option-descriptif
        position: relative
        img
          width: 120px
          height: 120px
          border-radius: $global-border-radius
          border: 1px solid $line
        small
          color: $body-color !important
        strong
          color: $black !important
        &:not(:last-child)
          border-bottom: 1px solid rgba(0,0,0,0.2)
        .option-item
          display: flex
          align-items: center
      .item
        border-bottom: 1px solid $white
        a
          text-decoration: none
        .app-collapse
          .app-collapse-content
            > div
              padding: 0
        .head
          position: relative
          padding: 1rem 1.3rem 1.2rem
          @include body
          color: white
          cursor: pointer
          display: flex
          align-items: center
          justify-content: space-between
          svg
            width: 1rem
            height: 1rem
            transform: rotate(0)
            transition: all 0.3s ease-in-out
          strong
            margin-left: 1rem
          img
            width: 24px
            filter: brightness(5)
            transition: all 0.3s ease-in-out
          &.active
            border-bottom: 1px solid $white
            svg
              transform: rotate(90deg)
              transition: all 0.3s ease-in-out
    .item-premium
      .option-card
        background-color: #323337
    .item-eko
      .option-card
          background-color: #323337
    .item-essentiel
      .option-card
        background-color: #323337
    .item-prime
      .option-card
        background-color: #323337
    .item-access
      .option-card
        background-color: #323337
    .option
      display: flex
      justify-content: space-between
      align-items: center
      padding: 1rem 0
      > a
        width: 100%
      &-card
        display: flex
        flex-direction: row
        border-radius: 24px
        width: 100%
        padding: 0.25rem
        &-img
          width: 120px
          height: 120px
          img
            border-radius: 20px
            width: 100%
            height: 100%
            object-fit: cover
        &-container
          display: flex
          flex-direction: row
          justify-content: space-between
          width: 100%
        &-content
          display: flex
          flex-direction: row
          justify-content: space-between
          margin-left: 1.5rem
          width: 100%
          > div:first-child
            display: flex
            flex-direction: column
            justify-content: center
            height: 100%
            small
              color: $blue-button !important
          > div:last-child
            display: flex
            align-items: center
            min-width: 70px
            strong
              margin: 0 0 0 0.5rem
              font-size: $font-sm !important
        &-btn
          display: flex
          justify-content: center
          align-items: center
          margin: 0 1rem
          span
            padding: 8px 14px
            background-color: $blue-button
            border-radius: 50%
          svg
            transform: rotate(180deg) scale(1.4)
      &.with-arrow
        padding: 0.11rem 0.75rem 0.11rem 0.25rem
        &:first-child
          padding-top: 0.3rem
        &:last-child
          padding-bottom: 0.3rem
      .label
        padding: 0 1rem
      strong
        display: block
        margin-bottom: 0.4rem
        @include body-bold
        color: white
        font-size: 1.2rem
        line-height: 1.2
      small
        @include infos
        color: lighten($body-color, 30%)
        font-size: 0.9rem
        line-height: 1.2
        font-weight: 400
      .price
        padding: 0 1rem
      .option-input
        margin-bottom: 0.4rem
      .option-input .value
        margin-left: 0
      .price, .option-input
        @include centered-container
  .custom-land-container
    background-color: white
    box-shadow: $global-box-shadow
    border-radius: 8px
    margin: 16px 0
    p
      @include body-bold
      color: $subtitle
    small
      @include infos
      color: $body-color
    .land-name
      padding: 10px
    .cell
      align-items: center
      display: flex
  .allotment-container
    background-color: white
    box-shadow: $global-box-shadow
    border-radius: 8px
    margin: 16px 0
    .allotment-header
      padding: 16px
      h5
        color: black
    .allotment-lands > .grid-x
      padding: 16px
      border-top: solid 1px $line
    .company
      margin-top: 8px
      p
        color: $secondary
      p.company-name
        color: $primary
        text-decoration: underline
        font-size: $font-sm
    .download
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      cursor: pointer
      svg
        width: 24px
      p
        color: $primary
        font-size: $font-xs
        line-height: 14px
    h3
      color: $title
      font-weight: 500
      margin-bottom: 0
    .land-name p
      font-size: $font-md
      font-weight: 400
    .land-radio
      align-items: center
      justify-content: center
      display: flex
      margin-left: 10px
  a
    text-decoration: none
  img
    max-width: 100%
    height: auto
    box-sizing: border-box
  .centered
    display: flex
    justify-content: center
    align-items: center
  .text-with-break
    white-space: pre-wrap
  .spinner-container
    margin-top: 50px
  .text-center
    text-align: center
  .highlight-missing
    color: $primary!important
  .intro
    width: 63px
  .share
    transform: rotate(-90deg)
    width: 20px
  .lot-nav
    button
      svg
        width: 10px
    &:first-child
      svg
        margin-right: 10px
    &:last-child
      svg
        margin-left: 10px
        transform: rotate(180deg)
  // Design system overwrite
  .app-button.error
    border-radius: 30px!important
    padding: 10px 30px!important
    font-weight: 400!important
    h4
      font-weight: 400!important
      text-transform: uppercase!important
      font-size: 12px!important
</style>
