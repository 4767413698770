<template>
  <div class="gallery-carousel">
      <img v-if="orderedImages[count]" style="object-fit: contain; aspect-ratio: 16/9;" :src="orderedImages[count].url" :class="loaded ? orderedImages[count].type : 'not-loaded'" @load="imageLoaded"/>
      <div class="arrows grid-x" v-if="orderedImages.length > 1">
        <span class="arrow left cell shrink" @click.stop.prevent="changeImage('prev')" >‹</span>
        <span class="arrow right cell shrink" @click.stop.prevent="changeImage('next')" >›</span>
      </div>
  </div>
</template>

<script>
export default {
  name: 'gallery-carousel',
  props: {
    images: {
      type: Array,
    },
    imagesType: {
      type: String,
      default: 'house',
    },
    onlyBlueprint: Boolean,
  },
  data() {
    return {
      count: 0,
      loaded: false,
    };
  },
  watch: {
    orderedImages() {
      this.count = 0;
    },
  },
  methods: {
    changeImage(direction) {
      this.loaded = false;
      if (direction === 'next') {
        this.count = (this.count + 1) % this.orderedImages.length;
      } else if (direction === 'prev') {
        this.count = (this.count - 1 + this.orderedImages.length) % this.orderedImages.length;
      }
    },
    imageLoaded() {
      this.loaded = true;
    },
  },
  computed: {
    // Ré-ordonnes les images pour avoir le plan ou l'extérieur en premier
    orderedImages() {
      const images = [...this.images];
      if (this.imagesType === 'blueprint') {
        return images.filter((img) => img.type === 'BLUEPRINT' || img.type === 'SECONDARY_IMAGE').sort((a) => (a.type === 'BLUEPRINT' ? -1 : 1));
      }
      return images.filter((img) => img.type === 'EXTERIOR_IMAGE');
    },
  },
};
</script>

<style lang='sass' scoped>
.gallery-carousel
  position: relative
  &:hover
    .arrows
      opacity: 1
  .arrows
    position: absolute
    display: flex
    @media (min-width: 1024px)
      opacity: 0
    @media (max-width: 1024px)
      opacity: 1
    flex-direction: row
    justify-content: space-between
    width: 100%
    top: calc(50% - 16px)
    transition: opacity 0.2s ease-in-out
    .arrow
      height: 30px
      color: white
      font-size: 48px
      padding: 0 10px
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.4)
      transform: rotate(0deg)
  img
    &.BLUEPRINT, &.SECONDARY_IMAGE, &.not-loaded
      aspect-ratio: 16 / 9
      object-fit: contain
</style>
