<template>
  <div class="diffusion-templates">
    <form ref="diffusion-templates" @submit.prevent="save">

      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Configuration diffusion</h1>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>

      <!-- TABS -->
      <tab-nav :tabs="tabs"/>

      <!-- BODY / FORMULAIRE -->
      <div class="body">
        <div v-if="!isLoading && brand">

          <!-- ANNONCE TERRAIN -->
          <div class="row">
            <h2>Annonce Terrain</h2>
          </div>

          <div class="grid-x grid-margin-x">
            <div class="cell small-12">
              <div class="card">
                <div class="grid-x grid-margin-x row">
                  <!-- DESCRIPTION -->
                  <div class="cell medium-5">
                    <app-textarea
                      class="description"
                      label="Description"
                      placeholder="Description"
                      v-model="brand.templateLandDescription"
                    />
                  </div>

                  <!-- PHOTOS PRINCIPALES -->
                  <div class="cell medium-7">
                    <div class="grid-x grid-margin-x">
                      <div class="cell shrink">
                        <app-label>Photo principale</app-label>
                        <app-upload-image
                          v-if="templateLandMainPhoto.url"
                          v-model="templateLandMainPhoto.url"
                          @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_MAIN_PHOTO)"
                          @remove="removeMedia(TemplateMedia.TEMPLATE_LAND_MAIN_PHOTO)"
                        />
                        <app-upload-image
                          v-else
                          @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_MAIN_PHOTO)"
                        />
                      </div>

                      <div class="cell shrink">
                        <app-label>Masque photo principale</app-label>
                        <app-upload-image
                          v-if="templateLandLayerPhoto.url"
                          v-model="templateLandLayerPhoto.url"
                          @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_LAYER_PHOTO)"
                          @remove="removeMedia(TemplateMedia.TEMPLATE_LAND_LAYER_PHOTO)"
                        />
                        <app-upload-image
                          v-else
                          @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_LAYER_PHOTO)"
                        />
                      </div>

                      <div class="cell auto"/>
                    </div>
                  </div>
                  <div class="cell variables">
                    <h5>Liste des variables disponibles :</h5>
                    <p>
                      {descriptif_commercial_terrain}, {descriptif_commercial_lotissement}.
                    </p>
                  </div>
                </div>

                <!-- PHOTOS SECONDAIRES -->
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label class="label">Photos secondaires (max {{ maxSecondaryMediaCount }})</app-label>
                  </div>
                </div>
                <div class="grid-x grid-margin-x row">
                  <div
                    v-for="(mediaCount, index) in landSecondaryMediaCount"
                    :key="`upload-media-input-${index}`"
                    class="cell shrink upload-media-input upload-media-input-list"
                  >
                    <app-upload-image
                      v-if="templateLandSecondaryPhotos[index] && templateLandSecondaryPhotos[index].url"
                      v-model="templateLandSecondaryPhotos[index].url"
                      @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO, index)"
                      @remove="removeMedia(TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO, index)"
                    />
                    <app-upload-image
                      v-else
                      @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO, index)"
                    />
                  </div>
                  <div class="cell auto"/>
                </div>
              </div>
            </div>
          </div>

          <!-- ANNONCE TERRAIN -->
          <div class="row">
            <h2>Annonce Terrain + Maison</h2>
          </div>

          <div class="grid-x grid-margin-x">
            <div class="cell small-12">
              <div class="card">
                <div class="grid-x grid-margin-x row">
                  <!-- DESCRIPTION -->
                  <div class="cell medium-5">
                    <app-textarea
                      class="description"
                      label="Description"
                      placeholder="Description"
                      v-model="brand.templateLandVersionDescription"
                    />
                  </div>

                  <!-- PHOTOS PRINCIPALES -->
                  <div class="cell medium-7">
                    <div class="grid-x grid-margin-x">
                      <div class="cell shrink">
                        <app-label>Photo principale</app-label>
                        <app-upload-image
                          v-if="templateLandVersionMainPhoto.url"
                          v-model="templateLandVersionMainPhoto.url"
                          @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_VERSION_MAIN_PHOTO)"
                          @remove="removeMedia(TemplateMedia.TEMPLATE_LAND_VERSION_MAIN_PHOTO)"
                        />
                        <app-upload-image
                          v-else
                          @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_VERSION_MAIN_PHOTO)"
                        />
                      </div>

                      <div class="cell shrink">
                        <app-label>Masque photo principale</app-label>
                        <app-upload-image
                          v-if="templateLandVersionLayerPhoto.url"
                          v-model="templateLandVersionLayerPhoto.url"
                          @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_VERSION_LAYER_PHOTO)"
                          @remove="removeMedia(TemplateMedia.TEMPLATE_LAND_VERSION_LAYER_PHOTO)"
                        />
                        <app-upload-image
                          v-else
                          @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_VERSION_LAYER_PHOTO)"
                        />
                      </div>

                      <div class="cell auto"/>
                    </div>
                  </div>

                  <div class="cell variables">
                    <h5>Liste des variables disponibles :</h5>
                    <p>{nombre_chambres}, {descriptif_commercial_terrain}, {descriptif_commercial_lotissement}, {description_version}, {telephone-1_OX}, {nom_partenaire} (uniquement pour les terrains T+M Partenaire)</p>
                  </div>
                </div>

                <!-- PHOTOS SECONDAIRES -->
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label class="label">Photos secondaires (seules les 6 premières images seront prises en compte)</app-label>
                  </div>
                </div>
                <div class="grid-x grid-margin-x row">
                  <div
                    v-for="(mediaCount, index) in landVersionSecondaryMediaCount"
                    :key="`upload-media-input-${index}`"
                    class="cell shrink upload-media-input upload-media-input-list"
                  >
                    <app-upload-image
                      v-if="templateLandVersionSecondaryPhotos[index] && templateLandVersionSecondaryPhotos[index].url"
                      v-model="templateLandVersionSecondaryPhotos[index].url"
                      @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO, index)"
                      @remove="removeMedia(TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO, index)"
                    />
                    <app-upload-image
                      v-else
                      @upload="(event) => addMedia(event, TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO, index)"
                    />
                  </div>
                  <div class="cell auto"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- LOADER -->
        <div v-else class="loader">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import auth from '@/services/auth';
import brandApi from '@/services/api/brand';
import TemplateMedia from '@/services/enums/templateMedia.enum';
import axios from 'axios';
import * as FileType from 'file-type';

export default {
  name: 'diffusion-templates',

  components: {
    TabNav,
  },

  metaInfo: {
    title: 'Configuration diffusion',
  },

  data() {
    return {
      tabs: [
        {
          route: {
            name: 'diffusions-quotas',
            params: {},
          },
          label: 'Gestion',
        },
        {
          route: {
            name: 'diffusions-statistics',
            params: {},
          },
          label: 'Statistiques',
        },
        {
          route: {
            name: 'diffusions-templates',
            params: {},
          },
          label: 'Templates',
        },
        {
          route: {
            name: 'diffusions-ubiflow',
            params: {},
          },
          label: 'Ubiflow',
        },
        {
          route: {
            name: 'diffusions-advertisement-packs',
            params: {},
          },
          label: 'Packs',
        },
        {
          route: {
            name: 'diffusions-logs',
            params: {},
          },
          label: 'Logs',
        },
      ],
      isLoading: false,

      landSecondaryMediaCount: 1,
      landVersionSecondaryMediaCount: 1,
      maxSecondaryMediaCount: 10,

      brand: null,

      templateLandMainPhoto: {},
      templateLandLayerPhoto: {},
      templateLandSecondaryPhotos: [],
      templateLandVersionMainPhoto: {},
      templateLandVersionLayerPhoto: {},
      templateLandVersionSecondaryPhotos: [],

      TemplateMedia,
    };
  },

  mounted() {
    this.getBrand();
  },

  methods: {
    // Récupère la marque
    async getBrand() {
      this.isLoading = true;

      try {
        this.brand = await auth.getBrand();

        // 1. Création de l'objet templateLandMainPhoto
        let landMainPhotoFile = null;

        // Si l'url existe, on récupère l'image pour créer le fichier
        if (this.brand.templateLandMainPhoto) {
          let file = null;
          const data = await this.getImageFile(this.brand.templateLandMainPhoto);

          if (data) file = data.data;

          if (file) {
            const mimeType = (await this.getImageType(file)).mime;
            landMainPhotoFile = new File([file], TemplateMedia.TEMPLATE_LAND_MAIN_PHOTO, { type: mimeType });

            this.templateLandMainPhoto = {
              file: landMainPhotoFile,
              url: this.brand.templateLandMainPhoto,
            };
          }
        }

        // 2. Création de l'objet templateLandLayerPhoto
        let landLayerPhotoFile = null;

        // Si l'url existe, on récupère l'image pour créer le fichier
        if (this.brand.templateLandLayerPhoto) {
          let file = null;
          const data = await this.getImageFile(this.brand.templateLandLayerPhoto);

          if (data) file = data.data;

          if (file) {
            const mimeType = (await this.getImageType(file)).mime;
            landLayerPhotoFile = new File([file], TemplateMedia.TEMPLATE_LAND_LAYER_PHOTO, { type: mimeType });

            this.templateLandLayerPhoto = {
              file: landLayerPhotoFile,
              url: this.brand.templateLandLayerPhoto,
            };
          }
        }

        // 3. Création du tableau d'objet templateLandSecondaryPhotos
        if (this.brand.templateLandSecondaryPhotos && this.brand.templateLandSecondaryPhotos.length > 0) {
          const getLandSecondaryPhotos = this.brand.templateLandSecondaryPhotos.map(async (templateLandSecondaryPhoto) => {
            let file = null;
            const data = await this.getImageFile(templateLandSecondaryPhoto);

            if (data) file = data.data;

            if (file) {
              const mimeType = (await this.getImageType(file)).mime;
              const landSecondaryPhotoFile = new File([file], TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO, { type: mimeType });

              return {
                file: landSecondaryPhotoFile,
                url: templateLandSecondaryPhoto,
              };
            }

            return {};
          });

          this.templateLandSecondaryPhotos = await Promise.all(getLandSecondaryPhotos);

          // On ajuste le nombre de vignette à afficher pour la liste des photos secondaire en fonction du nombre d'image déjà présentes
          if (this.templateLandSecondaryPhotos.length < 10) {
            this.landSecondaryMediaCount = this.templateLandSecondaryPhotos.length + 1;
          } else {
            this.landSecondaryMediaCount = this.templateLandSecondaryPhotos.length;
          }
        }

        // 4. Création de l'objet templateLandVersionMainPhoto
        let landVersionMainPhotoFile = null;

        // Si l'url existe, on récupère l'image pour créer le fichier
        if (this.brand.templateLandVersionMainPhoto) {
          let file = null;
          const data = await this.getImageFile(this.brand.templateLandVersionMainPhoto);

          if (data) file = data.data;

          if (file) {
            const mimeType = (await this.getImageType(file)).mime;
            landVersionMainPhotoFile = new File([file], TemplateMedia.TEMPLATE_LAND_VERSION_MAIN_PHOTO, { type: mimeType });

            this.templateLandVersionMainPhoto = {
              file: landVersionMainPhotoFile,
              url: this.brand.templateLandVersionMainPhoto,
            };
          }
        }

        // 5. Création de l'objet templateLandVersionLayerPhoto
        let landVersionLayerPhotoFile = null;

        // Si l'url existe, on récupère l'image pour créer le fichier
        if (this.brand.templateLandVersionLayerPhoto) {
          let file = null;
          const data = await this.getImageFile(this.brand.templateLandVersionLayerPhoto);

          if (data) file = data.data;

          if (file) {
            const mimeType = (await this.getImageType(file)).mime;
            landVersionLayerPhotoFile = new File([file], TemplateMedia.TEMPLATE_LAND_VERSION_LAYER_PHOTO, { type: mimeType });

            this.templateLandVersionLayerPhoto = {
              file: landVersionLayerPhotoFile,
              url: this.brand.templateLandVersionLayerPhoto,
            };
          }
        }

        // 6. Création du tableau d'objet templateLandVersionSecondaryPhotos
        if (this.brand.templateLandVersionSecondaryPhotos && this.brand.templateLandVersionSecondaryPhotos.length > 0) {
          const getLandVersionSecondaryPhotos = this.brand.templateLandVersionSecondaryPhotos.map(async (templateLandVersionSecondaryPhoto) => {
            let file = null;
            const data = await this.getImageFile(templateLandVersionSecondaryPhoto);

            if (data) file = data.data;

            if (file) {
              const mimeType = (await this.getImageType(file)).mime;
              const landVersionSecondaryPhotoFile = new File([file], TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO, { type: mimeType });

              return {
                file: landVersionSecondaryPhotoFile,
                url: templateLandVersionSecondaryPhoto,
              };
            }

            return {};
          });

          this.templateLandVersionSecondaryPhotos = await Promise.all(getLandVersionSecondaryPhotos);

          // On ajuste le nombre de vignette à afficher pour la liste des photos secondaire en fonction du nombre d'image déjà présentes
          if (this.templateLandVersionSecondaryPhotos.length < 10) {
            this.landVersionSecondaryMediaCount = this.templateLandVersionSecondaryPhotos.length + 1;
          } else {
            this.landVersionSecondaryMediaCount = this.templateLandVersionSecondaryPhotos.length;
          }
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.isLoading = false;
    },

    // Récupère le type d'un fichier (extension + mimeType)
    async getImageType(file) {
      return FileType.fromBuffer(file);
    },

    // Récupère une image via son url
    async getImageFile(url) {
      return axios.create().get(url, {
        responseType: 'arraybuffer',
        withCredentials: false,
        transformRequest: (data, headers) => {
          const header = { ...headers };
          delete header.common.Authorization;
          return data;
        },
      }).catch(() => null);
    },

    // Mise à jour de la marque
    async save() {
      this.isLoading = true;

      // 1. Mise à jour de la marque sans les photos
      const {
        templateLandLayerPhoto,
        templateLandMainPhoto,
        templateLandSecondaryPhotos,
        templateLandVersionMainPhoto,
        templateLandVersionLayerPhoto,
        templateLandVersionSecondaryPhotos,
        createdAt,
        updatedAt,
        deletedAt,
        ...data
      } = this.brand;

      try {
        await brandApi.update(this.brand.brandId, data);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour de la marque.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      // 2. Mise à jour des photos
      try {
        // 1. Mise à jour de la photo templateLandMainPhoto
        const landMainPhotoFile = new FormData();
        landMainPhotoFile.append('files', this.templateLandMainPhoto.file);
        landMainPhotoFile.append('type', TemplateMedia.TEMPLATE_LAND_MAIN_PHOTO);
        await brandApi.uploadPhotos(this.brand.brandId, landMainPhotoFile);

        // 2. Mise à jour de la photo templateLandLayerPhoto
        const landLayerPhotoFile = new FormData();
        landLayerPhotoFile.append('files', this.templateLandLayerPhoto.file);
        landLayerPhotoFile.append('type', TemplateMedia.TEMPLATE_LAND_LAYER_PHOTO);
        await brandApi.uploadPhotos(this.brand.brandId, landLayerPhotoFile);

        // 3. Mise à jour des photos templateLandSecondaryPhotos
        const landSecondaryPhotosFiles = new FormData();
        landSecondaryPhotosFiles.append('type', TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO);
        this.templateLandSecondaryPhotos.forEach((file) => {
          landSecondaryPhotosFiles.append('files', file.file);
        });
        await brandApi.uploadPhotos(this.brand.brandId, landSecondaryPhotosFiles);

        // 4. Mise à jour de la photo templateLandVersionMainPhoto
        const landVersionMainPhotoFile = new FormData();
        landVersionMainPhotoFile.append('files', this.templateLandVersionMainPhoto.file);
        landVersionMainPhotoFile.append('type', TemplateMedia.TEMPLATE_LAND_VERSION_MAIN_PHOTO);
        await brandApi.uploadPhotos(this.brand.brandId, landVersionMainPhotoFile);

        // 5. Mise à jour de la photo templateLandVersionLayerPhoto
        const landVersionLayerPhotoFile = new FormData();
        landVersionLayerPhotoFile.append('files', this.templateLandVersionLayerPhoto.file);
        landVersionLayerPhotoFile.append('type', TemplateMedia.TEMPLATE_LAND_VERSION_LAYER_PHOTO);
        await brandApi.uploadPhotos(this.brand.brandId, landVersionLayerPhotoFile);

        // 6. Mise à jour des photos templateLandVersionSecondaryPhotos
        const landVersionSecondaryPhotosFiles = new FormData();
        landVersionSecondaryPhotosFiles.append('type', TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO);
        this.templateLandVersionSecondaryPhotos.forEach((file) => {
          landVersionSecondaryPhotosFiles.append('files', file.file);
        });
        await brandApi.uploadPhotos(this.brand.brandId, landVersionSecondaryPhotosFiles);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour des photos.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.$notification.show({ text: 'L\'annonce a bien été mise à jour.' });

      this.getBrand();
    },

    // Enregistrement des photos avant la sauvegarde
    addMedia(event, type, index) {
      // Construction de l'url local
      const url = URL.createObjectURL(event);

      switch (type) {
        case TemplateMedia.TEMPLATE_LAND_MAIN_PHOTO:
          this.templateLandMainPhoto = { file: event, url };
          break;
        case TemplateMedia.TEMPLATE_LAND_LAYER_PHOTO:
          this.templateLandLayerPhoto = { file: event, url };
          break;
        case TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO:
          this.$set(this.templateLandSecondaryPhotos, index, { file: event, url });
          break;
        case TemplateMedia.TEMPLATE_LAND_VERSION_MAIN_PHOTO:
          this.templateLandVersionMainPhoto = { file: event, url };
          break;
        case TemplateMedia.TEMPLATE_LAND_VERSION_LAYER_PHOTO:
          this.templateLandVersionLayerPhoto = { file: event, url };
          break;
        case TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO:
          this.$set(this.templateLandVersionSecondaryPhotos, index, { file: event, url });
          break;
        default:
      }

      if (type === TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO || type === TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO) {
        this.updateSecondaryPhotosList(type);
      }
    },

    // Suppression d'un média
    removeMedia(type, index) {
      // Construction du fichier vide
      const file = new FormData();
      file.append('type', type);

      switch (type) {
        case TemplateMedia.TEMPLATE_LAND_MAIN_PHOTO:
          this.templateLandMainPhoto = { file, url: null };
          break;
        case TemplateMedia.TEMPLATE_LAND_LAYER_PHOTO:
          this.templateLandLayerPhoto = { file, url: null };
          break;
        case TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO:
          this.$set(this.templateLandSecondaryPhotos, index, { file, url: null });
          break;
        case TemplateMedia.TEMPLATE_LAND_VERSION_MAIN_PHOTO:
          this.templateLandVersionMainPhoto = { file, url: null };
          break;
        case TemplateMedia.TEMPLATE_LAND_VERSION_LAYER_PHOTO:
          this.templateLandVersionLayerPhoto = { file, url: null };
          break;
        case TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO:
          this.$set(this.templateLandVersionSecondaryPhotos, index, { file, url: null });
          break;
        default:
      }

      if (type === TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO || type === TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO) {
        this.updateSecondaryPhotosList(type);
      }
    },

    // Met à jour le nombre d'input file à afficher et tri les inputs pour afficher celui qui est vide en dernier
    updateSecondaryPhotosList(type) {
      if (type === TemplateMedia.TEMPLATE_LAND_SECONDARY_PHOTO) {
        const photosLength = this.templateLandSecondaryPhotos.filter((templateLandSecondaryPhoto) => templateLandSecondaryPhoto.url).length;

        if (photosLength < this.maxSecondaryMediaCount) {
          this.landSecondaryMediaCount = photosLength + 1;
        }

        this.templateLandSecondaryPhotos.sort((a, b) => (b.url !== null) - (a.url !== null));
      } else if (type === TemplateMedia.TEMPLATE_LAND_VERSION_SECONDARY_PHOTO) {
        const photosLength = this.templateLandVersionSecondaryPhotos.filter((templateLandVersionSecondaryPhoto) => templateLandVersionSecondaryPhoto.url).length;

        if (photosLength < this.maxSecondaryMediaCount) {
          this.landVersionSecondaryMediaCount = photosLength + 1;
        }

        this.templateLandVersionSecondaryPhotos.sort((a, b) => (b.url !== null) - (a.url !== null));
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.diffusion-templates
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .card
    @include card
    .variables
      margin-top: 0.5rem
  .card-2
    margin-top: 30px
  .description
    height: 100%
  .app-upload-image
    height: 130px
    max-width: 180px
  .upload-media-input-list .app-upload-image
      margin-bottom: 15px
  .button-add
    display: flex
    align-items: center
    height: 130px
    button
      display: flex
      align-items: center
      justify-content: center
      background-color: $primary
      height: 32px
      width: 32px
      border: 0
      border-radius: 50%
      box-shadow: 0 0 0 2px $off-white
      transition: .2s
      cursor: pointer
      &:hover, &:disabled
        opacity: .7
        transition: .2s
      &:disabled
        cursor: not-allowed
      svg
        height: 14px
        width: 14px
  .label
    margin: 20px 0 0 15px
</style>
