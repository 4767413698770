<template>
  <div class="app-calendar">
    <FullCalendar
      class="company-calendar"
      initialView="timegridWeek"
      ref="fullcalendar"
      :views="views"
      :locales="calendarLocales"
      :weekends="true"
      :events="showFullList ? events : fullEvents"
      :header="header"
      :customButtons="isFullListAvailable && customButtons"
      :displayEventEnd="true"
      :buttonText="{ list: 'Tous les rendez-vous', listMonth: 'Liste par mois', listYear: 'Liste par année', dayGridMonth: 'Calendrier' }"
      @eventClick="$emit('eventClick', $event)"
      @dateClick="$emit('dateClick', $event)"
      @viewSkeletonRender="handleChangeView"
      :selectable="true"
      :plugins="calendarPlugins"
      :minTime="minTime"
      :maxTime="maxTime"
    />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import interactionPlugin from '@fullcalendar/interaction';

export default {
  name: 'app-calendar',
  components: {
    FullCalendar,
  },
  props: {
    // Événements principaux
    events: {
      type: Array,
      default: () => [],
      /** FORMAT ATTENDU:
       * id (opt): id de l'événement
       * start (required): date de début format date-parseable
       * end (opt): date de fin format date-parseable
       * title (required): nom de l'événement
       * classNames (opt): array de classes CSS à appliquer à l'événement
       */
    },
    // Événements affichés grisés et non-accessibles
    disabledEvents: {
      type: Array,
      default: () => [],
      /** FORMAT ATTENDU:
       * id (opt): id de l'événement
       * start (required): date de début format date-parseable
       * end (opt): date de fin format date-parseable
       * title (required): nom de l'événement
       * classNames (opt): array de classes CSS à appliquer à l'événement
       */
    },
    // Si la liste de tous les événements principaux est visible
    isFullListAvailable: {
      type: Boolean,
      default: false,
    },
    minTime: {
      type: String,
      required: false,
    },
    maxTime: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      calendarLocales: [frLocale],
      dateClick: this.handleDateClick,
      // Boutons customs de header
      customButtons: {
        viewList: {
          text: 'Liste',
          click: () => {
            this.showFullList = true;
          },
        },
        viewCalendar: {
          text: 'Calendrier',
          click: () => {
            this.showFullList = false;
          },
        },
      },
      calendarApi: null,
      showFullList: false,
      currentView: 'timeGridWeek',
    };
  },
  mounted() {
    this.calendarApi = this.$refs.fullcalendar.getApi();
  },
  watch: {
    showFullList() {
      if (this.showFullList) {
        this.calendarApi.changeView('fullList');
      } else {
        this.calendarApi.changeView('timeGridWeek');
      }
    },
  },
  computed: {
    fullEvents() {
      let events = [...this.events];
      const disabledEvents = this.disabledEvents.map((evt) => {
        const event = { ...evt };
        if (evt.classNames && evt.classNames.length) {
          event.classNames.push('disabled');
        } else {
          event.classNames = ['disabled'];
        }
        return event;
      });

      if (this.currentView !== 'fullList') {
        events = events.concat(disabledEvents);
      }

      return events;
    },
    calendarPlugins() {
      const calendarPlugins = [timeGridPlugin, dayGridPlugin, interactionPlugin];
      if (this.isFullListAvailable) {
        calendarPlugins.push(listPlugin);
      }
      return calendarPlugins;
    },
    header() {
      if (this.isFullListAvailable) {
        return this.showFullList
          ? {
            left: '',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,fullList',
          }
          : {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,fullList',
          };
      }
      return {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,fullList',
      };
    },
    views() {
      if (!this.isFullListAvailable) {
        return ['timeGridWeek'];
      }
      const [start] = this.events.map((evt) => new Date(evt.start)).sort((a, b) => a.getTime() - b.getTime());
      const [end] = this.events.map((evt) => new Date(evt.end)).sort((a, b) => b.getTime() - a.getTime());

      return {
        fullList: {
          type: 'list',
          visibleRange: {
            start,
            end,
          },
        },
      };
    },
  },
  methods: {
    handleChangeView(ev) {
      this.currentView = ev.view.type;
    },
  },
};
</script>

<style lang="sass">
@import '~@fullcalendar/core/main.css'
@import '~@fullcalendar/daygrid/main.css'
@import '~@fullcalendar/timegrid/main.css'
@import '~@fullcalendar/list/main.css'
.app-calendar
  padding: 16px
  background: white
  h2
    color: $gray-medium
    font-size: 20px
  .fc-event
    span
      color: white
  .fc-icon
    font-family: fcicons !important
    color: white
</style>
